import React, {useRef, useState} from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Modal from '@material-ui/core/Modal';

import DropDownMenu from 'components/dropDownMenu/DropDownMenu';
import showPopUp, {PopUp} from 'components/popUp/PopUp';
import { AmiiboInfoPage } from '../../../amiiboInfoPage/AmiiboInfoPage';

import { putAmiibo, putAmiiboBin, deleteAmiibo, getAmiiboBin, putToSubmissionApp, putToExternalDomain } from 'api/AmiiboApi';
import { createAlert } from 'ducks/alertsSlice';
import { useCopyImage } from '../../../../custom-hooks/use-html2canvas';


const AmiiboCard = ({amiibo, ruleset, ...props}) => {
    const picture_source = 'https://raw.githubusercontent.com/N3evin/AmiiboAPI/master/images/icon_' + amiibo.character_metadata.substring(0,8) + '-' + amiibo.character_metadata.substring(8, 16) + '.png';
    let classNames = 'amiibo-card';
    
    const [amiiboCardRef, copyAmiiboCard] = useCopyImage()
    const inputEl = useRef(null);
    const [displayDropdown, setDisplayDropdown] = useState('false');
    const [displayPopUp, setDisplayPopUp] = useState(false);
    const [amiiboID, setAmiiboID] = useState(null);

    const dispatch = useDispatch();

    if(amiibo.match_selection_status == "ACTIVE"){
        classNames += ' active';
    } else if(amiibo.match_selection_status == "STANDBY"){
        classNames += ' standby';
    }

    const handleRadioButtonOnClick = async (event) => {
        amiibo.match_selection_status = event.target.value
        const response = await putAmiibo(amiibo);
        if (!response.error){
            props.updateAmiibo(response.data.data);
        }
    }

    const handleDeleteAmiibo = async () => {
        const response = await deleteAmiibo(amiibo);
        if (!response.error){
            props.deleteAmiibo();
        }

    };
    let footer;
    if(props.submissionUrl){
        footer = (
            <button className="confirmation" onClick={()=>{
                putToSubmissionApp(amiibo.id, props.submissionUrl)
            }}>Submit to Tournament</button>
        )
    } else if (props.externalUrl) {
        let buttonText
        if(props.submissionButtonText) {
            buttonText = props.submissionButtonText
        } else {
            buttonText = "Submit to Tournament"
        }
        footer = (
            <button className="confirmation" onClick={()=>{
                putToExternalDomain(amiibo.id, props.externalUrl)
            }}>{buttonText}</button>
        )
    } else {
        footer = (
            <>
                <div>
                    <input type="radio" name={`${amiibo.id}-status`} onClick={handleRadioButtonOnClick}
                        checked={amiibo.match_selection_status == "ACTIVE"} value="ACTIVE"
                    /> Active
                </div>
                <div>
                    <input type="radio" name={`${amiibo.id}-status`} onClick={handleRadioButtonOnClick}
                        checked={amiibo.match_selection_status == "STANDBY"} value="STANDBY"
                    /> Standby
                </div>
                <div>
                    <input type="radio" name={`${amiibo.id}-status`} onClick={handleRadioButtonOnClick}
                        checked={amiibo.match_selection_status == "INACTIVE"} value="INACTIVE"
                    /> Inactive
                </div>
            </>
        )
    }
    
    

    const editOnClickHandler = () => {
        setDisplayDropdown(!displayDropdown);
        inputEl.current.click();
    };

    const onFileChangeHandler = async () => {

        const updatedAmiibo = inputEl.current.files[0];
        if(updatedAmiibo && updatedAmiibo != ''){
            let response = await putAmiiboBin(updatedAmiibo, amiibo.id);

            if (!response.error) {
                props.updateAmiibo(response.data.data);
            }
            
            inputEl.current.value = '';
        } 
    };

    //showPopUp('popUp', <AmiiboInfoPage amiibo_id={amiiboID} />, displayPopUp, () => setDisplayPopUp(!displayPopUp));

    const loadAmiiboInfoPage = (amiibo_id) => {
        setDisplayDropdown(!displayDropdown);
        setDisplayPopUp(!displayPopUp);
        setAmiiboID(amiibo_id);
    }

    return (
        <React.Fragment>
        <PopUp content={<AmiiboInfoPage amiibo_id={amiiboID} />} state={displayPopUp} closeFunc={() => setDisplayPopUp(!displayPopUp)}/>
        <div className={classNames} ref={amiiboCardRef}>
            <header>
                <span >{amiibo.name}</span>
                <div className="ruleset-name">({ruleset?.name})</div>
                <div style={{position: 'relative'}}>
                    <FontAwesomeIcon icon={faEllipsisV} onClick={() => setDisplayDropdown(!displayDropdown)} />
                    <DropDownMenu
                        style={ displayDropdown ? {display: 'none'}  : {}}
                        listItems={[
                            , {name: "Amiibo Info", onClick: () => {loadAmiiboInfoPage(amiibo.id)}}
                            , {name: "Copy Image", onClick: () => copyAmiiboCard()}
                            , {name: "Download Bin", onClick: () => {getAmiiboBin(amiibo.id)}}
                            , {name:'Edit', onClick: editOnClickHandler }
                            , {name:'Delete', onClick: ()=>{
                                setDisplayDropdown('false'); 
                                dispatch(createAlert({
                                    type: "WARNING"
                                    , message: "Are you sure you want to delete "+amiibo.name+"?"
                                    , confirmationCallback: handleDeleteAmiibo
                            }))}}
                            , {name: !navigator.clipboard ? amiibo.id : "Copy Amiibo Id", onClick: !navigator.clipboard ? null : async ()=>{
                                setDisplayDropdown('false');
                                await navigator.clipboard.writeText(amiibo.id)
                                dispatch(createAlert({
                                    type: "SUCCESS"
                                    , message: "Copied id to clipboard: "+amiibo.id
                            }))}}
                    ]}
                    />
                </div>
            </header>
            <img src={picture_source} />
            <ul>
                <li>Rating: {Number(amiibo.rating).toFixed(2)}</li>
                <li>Win Rate: {Number(amiibo.win_percentage).toLocaleString(undefined,{style: 'percent', minimumSignificantDigits: 3})}</li>
                <li>Wins: {amiibo.wins}</li>
                <li>Losses: {amiibo.losses}</li>
                <li>Total: {amiibo.total_matches}</li>
            </ul>
            <footer>
                {footer}
                <input type="file" id="file" accept=".bin" ref={inputEl} onChange={onFileChangeHandler} style={{display: 'none'}}/>
            </footer>
        </div>
        </React.Fragment>
    );
};

export default AmiiboCard;