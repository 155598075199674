import React, {useEffect, useState} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import UI_NAVIGATION_CONSTANTS from "constants/uiNavigationConstants.js"


const Navigation = (props) => {
    const history = useHistory();
    const handleRouteChange = event => { 
        history.push(event.target.value);
    }

    // Used to render differently for mobile and desktop
    // Does not update on size change, only when page is loaded
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const location = useLocation();
    let routes = [
        {"displayText":"Home", "link":UI_NAVIGATION_CONSTANTS.LANDING_PAGE}
    ]
    if(props.user?.id){
        routes.push({"displayText":"My Amiibo", "link":UI_NAVIGATION_CONSTANTS.MY_AMIIBO_PAGE});
        routes.push({"displayText":"My Matches", "link":UI_NAVIGATION_CONSTANTS.USER_SINGLES_MATCHES_PAGE})
    }
    routes.push({"displayText":"Leaderboard", "link":UI_NAVIGATION_CONSTANTS.LEADERBOARD})
    routes.push({"displayText": "Tier List", "link":UI_NAVIGATION_CONSTANTS.TIER_LIST})
    routes.push({"displayText":"FAQ", "link":UI_NAVIGATION_CONSTANTS.FAQ})

    if (isMobile) {
        return (
            <div className="navigation">
                <select onChange={handleRouteChange} className="nav-select">
                    {routes.map((route, i)=>{
                        let isCurrentPage;
                        if (route.link == location.pathname){
                            isCurrentPage = "selected"
                        }
                        return (
                            <option key={route.link} value={route.link} selected={isCurrentPage}>
                                {route.displayText}
                            </option>
                        )
                    })}
                </select>
            </div>
        )
    }
    else {
        return (
            <div className="navigation">
                {routes.map((route, i)=>{
                    let classNames = "nav-item"
                    if (route.link == location.pathname){
                        classNames += " active"
                    }
                    return (
                        <div key={route.link} className={classNames}>
                            <Link to={route.link}>{route.displayText}</Link>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Navigation
