import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';


import { setUser } from 'ducks/userSlice';
import API_CONSTANTS from "./ApiConstants";

export const getMatchUrl = async (created_at_timestamp, stream_url) => {
    return await axios.request({
        url: API_CONSTANTS.GET_MATCH_URL_ENDPOINT.path
        , method: API_CONSTANTS.GET_MATCH_URL_ENDPOINT.method
        , params: {created_at_timestamp, stream_url}
    })
}

export const useGetPlayableCharacters = () => {
    const [characters, setCharacters] = useState(); 

    useEffect(() => {
        (async () => {
            const response = await getPlayableCharacters()
            setCharacters(response?.data?.data)
        })()
    }, []);

    return [characters, setCharacters];
}

export const getPlayableCharacters = async () => {
    return await axios.request({
        url: API_CONSTANTS.GET_PLAYABLE_CHARACTERS.path
        , method: API_CONSTANTS.GET_PLAYABLE_CHARACTERS.method
    })
}