import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import axios from 'axios';

import UI_NAVIGATION_CONSTANTS from 'constants/uiNavigationConstants'

import {AccountInfo} from 'pages/accountInfo/AccountInfo.js';
import {AmiibosPage} from 'pages/amiibosPage/AmiibosPage';
import {FrequentlyAskedQuestions} from 'pages/frequentlyAskedQuestions/FrequentlyAskedQuestions.js';
import {Home} from 'pages/home/Home.js';
import {Leaderboard} from 'pages/leaderboard/Leaderboard.js'
import { TierListPage } from './pages/tierListPage/TierListPage';
import {UserSinglesMatchesPage} from 'pages/userSinglesMatchesPage/UserSinglesMatchesPage';


import Header from 'components/header/Header'
import Alerts from 'components/alerting/Alerts'

import { useGetCurrentUser }  from 'api/UserApi'
import { selectUser } from 'ducks/userSlice';
import { createAlert } from 'ducks/alertsSlice'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas, faCheckSquare, faCoffee, faToggleOn, faToggleOff, faTimesCircle, faSort } from '@fortawesome/free-solid-svg-icons';

library.add(fab, faCheckSquare, faCoffee);
library.add(fas, faToggleOn, faToggleOff);
library.add(faTimesCircle, faSort);


const App = (props) => {
    useGetCurrentUser()
    const dispatch = useDispatch()
    const user = useSelector(selectUser);
    const pathBeforeLogin = localStorage.getItem('pathBeforeLogin');
    const history = useHistory();

    useEffect(()=>{
        if (user?.id && pathBeforeLogin){
            localStorage.removeItem('pathBeforeLogin')
            if(pathBeforeLogin != '/'){
                history.push(pathBeforeLogin)
            } else {
                history.push(UI_NAVIGATION_CONSTANTS.MY_AMIIBO_PAGE)
            }

        }
    }, [pathBeforeLogin, user?.id])
    

    useEffect(() => {
        const fr = new FileReader();
        const errorFunction = (data, error) => {
            if (data?.message){
                dispatch(createAlert({
                    "type": "ERROR"
                    , "message": data?.message
                }));
            } else {
                dispatch(createAlert({
                    "type": "ERROR"
                    , "message": error?.response?.statusText
                }));
            }
        }
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            const data = response.data;
            if (data.message){
                dispatch(createAlert({"message": data.message}));
            }
            return response;
          }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const data = error?.response?.data;
            console.log("Error in request: ", error?.response)
            if (data instanceof Blob){
                fr.onload = () => {
                    errorFunction(JSON.parse(fr.result), error)
                }
                fr.readAsText(data)
            } else {
                errorFunction(data, error)
            }
            
            return Promise.reject(error);
          });
    }, [dispatch])

    return (
        <div className="App">
            <Header />
            <Switch>
                <Route exact path={UI_NAVIGATION_CONSTANTS.LANDING_PAGE} render={(props) => <Home {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.ACCOUNT_INFO} render={(props) => <AccountInfo {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.MY_AMIIBO_PAGE} render={(props) => <AmiibosPage {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.FAQ} render={(props) => <FrequentlyAskedQuestions {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.LEADERBOARD} render={(props) => <Leaderboard {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.TIER_LIST} render={(props) => <TierListPage {...props} />} />
                <Route path={UI_NAVIGATION_CONSTANTS.USER_SINGLES_MATCHES_PAGE} render={(props) => <UserSinglesMatchesPage {...props} />} />
            </Switch>
            <Alerts />
        </div>
    );
}

export default App;