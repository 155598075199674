import React from 'react';

let FrequentlyAskedQuestions = (props) => {
    // TODO pull faq from a db instead of having it hardcoded here
    let faq = [{"q": "How can I submit my amiibo?", "a": "First, you need to get the .bin file from your amiibo. If you have an Apple phone, then the <a href='https://amiibodoctor.com/how-to-use-an-iphone-to-submit-amiibo-files-explained-simply/'>NFC tools</a> method works best. If you have an Android phone, then <a href='https://exion-vault.com/2021/07/02/ssbu-amiibo-tagmo/'>Tagmo</a> is your best bet. Alternatively, you can buy a <a href='https://exion-vault.com/2021/04/29/ssbu-amiibo-powersaves/'>Powersaves</a>, a device for backing up amiibo to your computer. Once you have your amiibo bins, log into Amiibots using your twitch account and submit your amiibo!", "order": 1},
    {"q": "How can I watch my amiibo fight?", "a": "You can click the timestamp links on your <a href='/my_matches'>my matches</a> page to view matches that have already happened. If you want to know when your amiibo is fighting live, join the <a href='https://discord.gg/6sY5NjTeYD'>amiibots discord</a> and DM untitled1991 with your twitch name. A discord bot in the server will message you when your match is ready. You can also <a href='https://discord.com/api/oauth2/authorize?client_id=994460350151925860&permissions=275414895680&scope=applications.commands%20bot'>invite this bot</a> to other servers that it can message you from.", "order": 2},
    {"q": "What does Active/Standby/Inactive mean?", "a": "Amiibots uses a matchmaking system. When creating a match, the first amiibo is chosen randomly from all active amiibo. The second amiibo is selected from a pool of active and standby amiibo that have a similar rating to the first amiibo. Inactive amiibo will never be selected for a match. It's recommended to always have at least 1 active amiibo and amiibo on standby from a variety of ratings to maximize your games.", "order": 3},
    {"q": "How can I get more matches?", "a": "There are two methods for getting more matches. Subscribing to the <a href='https://www.twitch.tv/amiibots'>Amiibots twitch channel</a> gives increased odds for games based on your sub-tier. You can also redeem loyalty points for an increased chance of games. These bonuses apply to both streams, and are automatically updated at midnight MST each night", "order": 4},
    {"q": "What are loyalty points and how do I get them?", "a": "Loyalty points give your amiibo a higher chance to be chosen for a match. You can get them by redeeming channel points on the <a href='https://www.twitch.tv/amiibots'>main Amiibots stream</a>.", "order": 5},
    {"q": "Will doing ___ get me banned from Amiibots?", "a":"Creating alternate accounts will result in a ban from Amiibots. Using edited amiibo (both spirit and move editing) is currently allowed. Amiibots reserves the right to ban users at any time for other reasons as well. Additionally, loyalty points can be revoked as a form of punishment instead.", "order": 6}]
    return (
        <div className="faq-body">
            {faq.map((question, i) => {
                // Probably better ways to do this, but makes links possible
                // if text contains anchor element, parse for links
                if (question.a.includes("</a>")) {
                    try {
                    // tag parsing code is modified from https://github.com/facebook/react-native/issues/614#issuecomment-139064180
                    // splits up text into text and tags
                    let tag_split = question.a.split(/(<.*?>.*?<\/.*?>)/g)
                    
                    for (var i = 1; i < tag_split.length; i += 2) {
                        // extract the word to be linked and the link.
                        let hypertext = tag_split[i].replace(/<.*?>(.*?)<\/.*?>/, '$1');
                        let link = tag_split[i].split(/'(.*?)'/)[1];

                        // convert text to actual link tag
                        tag_split[i] = <a href={link}>{hypertext}</a>;
                      }
                    return (
                        <div key={question.q}>
                            <h3>{question.q}</h3>
                            <p>{tag_split}</p>
                        </div>
                    )
                    }
                    catch {
                        // pass
                        // if link parsing goes wrong it will render as plaintext
                    }
                }
                return (
                    <div key={question.q}>
                        <h3>{question.q}</h3>
                        <p>{question.a}</p>
                    </div>
                )

            })}
        </div>
    )
}

export {FrequentlyAskedQuestions}