import {useEffect, useState} from 'react';
import axios from 'axios';

import API_CONSTANTS from "./ApiConstants";


export const useGetSinglesMatchesByUserId = (user_id) => {
    const [matches, setMatches] = useState(); 

    useEffect(() => {
        if(user_id){
            (async () => {
                const response = await getSinglesMatchesByUserId(user_id);
                setMatches(response.data.data)
            })()
        }
    }, [user_id]);

    return [matches, setMatches];
}

const getSinglesMatchesByUserId = async (user_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.SINGLES_MATCHES_BY_USER_ID_ENDPOINT.path + user_id
        , method: API_CONSTANTS.SINGLES_MATCHES_BY_USER_ID_ENDPOINT.method
    })
}

export const useGetSinglesMatchesByAmiiboId = (amiibo_id) => {
    const [matches, setMatches] = useState(); 

    useEffect(() => {
        if(amiibo_id){
            (async () => {
                const response = await getSinglesMatchesByAmiiboId(amiibo_id);
                setMatches(response.data.data)
            })()
        }
    }, [amiibo_id]);

    return [matches, setMatches];
}

const getSinglesMatchesByAmiiboId = async (amiibo_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.SINGLES_MATCHES_BY_AMIIBO_ID_ENDPOINT.path + amiibo_id
        , method: API_CONSTANTS.SINGLES_MATCHES_BY_AMIIBO_ID_ENDPOINT.method
    })
}
