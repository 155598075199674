import React from 'react';

import { postAmiiboBinConversionFromNfcTools } from 'api/AmiiboApi';
import ConversionFileUpload from 'components/fileUploadCard/FileUploadCard';

const fileUploadBody = (
    <>
        <p>Looking to join Amiibots, but don't know how to get your bin file? Amiibo Doctor has written a guide for just that!</p>
        <p>Iphone users can finally join the fun by following this <a href="http://amiibodoctor.com/how-to-use-an-iphone-to-submit-amiibo-files-explained-simply">guide</a>, which 
        uses a program called NFC Tools from the Apple play store.</p>
        <p>There's an NFC Tools for Android phones as well, and works the same way 
        (with slightly different screens); however, other tools already existed for Android, such as <a href="https://amiibodoctor.com/2020/05/06/installing-tagmo-explained-simply/">Tagmo</a> and <a href="https://amiibodoctor.com/competing-with-amiibo-the-starters-guide-to-tagmo-and-powersaves/">Powersaves</a></p>

        <p>If you are have your text file exported from NFC Tools, you can upload the file below to get it converted to a bin!</p>
    </>
)

let Home = (props) => {
    return (
        <div className="home-page">
            <h3>Welcome to Amiibots, the Amiibo Proving Grounds!</h3>
            <ConversionFileUpload  
                fileUploadFunction = {postAmiiboBinConversionFromNfcTools}
                title="Convert NFC Tools Export to Bin File"
                bodyText={fileUploadBody}
            />
            <p>
                Here you can test your amiibo against a variety of opponents. 
                Matches are streamed live 24/7 at the <a href="https://www.twitch.com/amiibots">Amiibots Twitch Channel</a> for Vanilla fights,
                and at <a href="https://www.twitch.com/amiibotsmaze">Amiibots Pacmaze</a> for Spirit fights.
                Periodically, matches will also be streamed to <a href="https://www.twitch.com/amiibotszebes">Amiibots Zebes</a>
                , which is hosted by <a href="https://www.twitch.com/splicestream">Splice.</a>
                 The Amiibots system keeps track of who won the match, and can provide useful statistics to help improve your amiibo for the next tournament. 
                To get started, you'll need the data from your amiibo (known as an amiibo bin file) Check out the guides listed in the box to the right for more details on getting this file!
                Once you have your bin file, login with a Twitch account by click the login button above, and upload the file. It's that easy!
                Still need help or have questions? This <a href="https://exion-vault.com/2021/08/05/ssbu-amiibo-amiibots/" target="_blank"> guide from Exion Vault</a> goes more in-depth!
            </p>
            <p>
                Currently 2 amiibo are randomly picked for a match, though the system does favor selecting amiibo from trainers that haven't gone in a while.
                Twitch Subs also get an increased selection chance depending on their sub tier.
                You can also redeem channel points to increase your odds of appearing!
                Both of these must be done on the main amiibots channel, but affects rates on all channels.
                Betting has also been added in twitch chat! Use the command !bet command in chat to learn more!
                As the website develops, we'll be looking for more ways to reward viewership, and make the stream more fun!
            </p>
            <p>
                For community written guides, visit the <a href="https://amiibodoctor.com/master-list-of-training-guides/">Amiibo Doctor Website </a>
                To get the latest info on amiibo training, be sure to visit <a href="https://exion-vault.com/">Exion Vault</a>.
                If you are looking for tournaments to participate in, join <a href="https://discord.gg/xFFhytb">their Discord Server</a>.
            </p>
        </div>
    )
}

export {Home}
