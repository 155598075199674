import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function AlertBox(props) {

    let className = "alert-box"
    if (props.type == "SUCCESS") {
        className += " success"
    } else if (props.type == "WARNING") {
        className += " warning"
    } else if (props.type == "ERROR") {
        className += " error"
    }
    
    if (props.className) {
        className += " "+props.className
    }

    let title = <>{props.type}</>
    if (props.dismissable){
        title = <>{props.type} <a onClick={props.dismissFunction}><FontAwesomeIcon icon="times-circle" /></a></>
    }

    let footer = <></>
    if (props.confirmationCallback || props.cancellationCallback){
        footer = (
            <footer>
                <button className="confirmation" onClick={()=>{props?.confirmationCallback?.(); props.dismissFunction();}}>Confirm</button>
                <button className="cancellation" onClick={()=>{props?.cancellationCallback?.(); props.dismissFunction();}}>Cancel</button>
            </footer>
        )
    }
    return (
        <div className={className}>
            <header>{title}</header>
            <section>{props?.alert?.message?.split?.('\n')?.map((messageChunk, i)=>{
                if (i == 0){
                    return <>{messageChunk}</>
                } else {
                    return <><br />{messageChunk}</>
                }
            })}</section>
            {footer}
        </div>
    );
}

export default AlertBox;