import {useEffect, useState} from 'react';

import API_CONSTANTS from "./ApiConstants";
import axios from 'axios';


export const useGetAllSpiritSkills = () => {
    const [skills, setSkills] = useState()
    useEffect(() => {
            (async () => {
                const response = await getAllSpiritSkills()
                setSkills(response)
            }
            )()}, []);
    return [skills, setSkills]
}

export const getAllSpiritSkills = async () => {
    return await axios.request({
        url: API_CONSTANTS.GET_SPIRIT_SKILL_LIST.path,
        method: API_CONSTANTS.GET_SPIRIT_SKILL_LIST.method
    })
}
