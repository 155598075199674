import React from 'react';
import BanjoKazooie from '../../../images/stockIcons/banjo_and_kazooie.png'
import Bayonetta from '../../../images/stockIcons/bayonetta.png'
import Bowser from '../../../images/stockIcons/bowser.png'
import BowserJr from '../../../images/stockIcons/bowser_jr.png'
import Byleth from '../../../images/stockIcons/byleth.png'
import CaptainFalcon from '../../../images/stockIcons/captain_falcon.png'
import Chrom from '../../../images/stockIcons/chrom.png'
import Cloud from '../../../images/stockIcons/cloud.png'
import Corrin from '../../../images/stockIcons/corrin.png'
import Daisy from '../../../images/stockIcons/daisy.png'
import DarkPit from '../../../images/stockIcons/dark_pit.png'
import DarkSamus from '../../../images/stockIcons/dark_samus.png'
import DiddyKong from '../../../images/stockIcons/diddy_kong.png'
import DonkeyKong from '../../../images/stockIcons/donkey_kong.png'
import DrMario from '../../../images/stockIcons/dr_mario.png'
import DuckHunt from '../../../images/stockIcons/duck_hunt.png'
import Falco from '../../../images/stockIcons/falco.png'
import Fox from '../../../images/stockIcons/fox.png'
import Ganondorf from '../../../images/stockIcons/ganondorf.png'
import Greninja from '../../../images/stockIcons/greninja.png'
import Hero from '../../../images/stockIcons/dq_hero.png'
import IceClimbers from '../../../images/stockIcons/ice_climbers.png'
import Ike from '../../../images/stockIcons/ike.png'
import Incineroar from '../../../images/stockIcons/gaogaen.png'
import Inkling from '../../../images/stockIcons/inkling.png'
import Isabelle from '../../../images/stockIcons/isabelle.png'
import Jigglypuff from '../../../images/stockIcons/jigglypuff.png'
import Joker from '../../../images/stockIcons/joker.png'
import Kazuya from '../../../images/stockIcons/kazuya.png'
import Ken from '../../../images/stockIcons/ken.png'
import KingDedede from '../../../images/stockIcons/king_dedede.png'
import KingKRool from '../../../images/stockIcons/king_k_rool.png'
import Kirby from '../../../images/stockIcons/kirby.png'
import Link from '../../../images/stockIcons/link.png'
import LittleMac from '../../../images/stockIcons/little_mac.png'
import Lucario from '../../../images/stockIcons/lucario.png'
import Lucas from '../../../images/stockIcons/lucas.png'
import Lucina from '../../../images/stockIcons/lucina.png'
import Luigi from '../../../images/stockIcons/luigi.png'
import Mario from '../../../images/stockIcons/mario.png'
import Marth from '../../../images/stockIcons/marth.png'
import MegaMan from '../../../images/stockIcons/mega_man.png'
import MetaKnight from '../../../images/stockIcons/meta_knight.png'
import Mewtwo from '../../../images/stockIcons/mewtwo.png'
import MiiBrawler from '../../../images/stockIcons/mii_brawler.png'
import MiiGunner from '../../../images/stockIcons/mii_gunner.png'
import MiiSwordfighter from '../../../images/stockIcons/mii_swordfighter.png'
import MinMin from '../../../images/stockIcons/min_min.png'
import MrGameWatch from '../../../images/stockIcons/mr_game_and_watch.png'
import Ness from '../../../images/stockIcons/ness.png'
import Olimar from '../../../images/stockIcons/olimar.png'
import PacMan from '../../../images/stockIcons/pac_man.png'
import Palutena from '../../../images/stockIcons/palutena.png'
import Peach from '../../../images/stockIcons/peach.png'
import Pichu from '../../../images/stockIcons/pichu.png'
import Pikachu from '../../../images/stockIcons/pikachu.png'
import PiranhaPlant from '../../../images/stockIcons/packun_flower.png'
import Pit from '../../../images/stockIcons/pit.png'
import PokemonTrainer from '../../../images/stockIcons/pokemon_trainer.png'
import PyraMythra from '../../../images/stockIcons/pyra.png'
import Rob from '../../../images/stockIcons/rob.png'
import Richter from '../../../images/stockIcons/richter.png'
import Ridley from '../../../images/stockIcons/ridley.png'
import Robin from '../../../images/stockIcons/robin.png'
import RosalinaLuma from '../../../images/stockIcons/rosalina_and_luma.png'
import Roy from '../../../images/stockIcons/roy.png'
import Ryu from '../../../images/stockIcons/ryu.png'
import Samus from '../../../images/stockIcons/samus.png'
import Sephiroth from '../../../images/stockIcons/sephiroth.png'
import Sheik from '../../../images/stockIcons/sheik.png'
import Shulk from '../../../images/stockIcons/shulk.png'
import Simon from '../../../images/stockIcons/simon.png'
import Snake from '../../../images/stockIcons/snake.png'
import Sonic from '../../../images/stockIcons/sonic.png'
import Sora from '../../../images/stockIcons/sora.png'
import Steve from '../../../images/stockIcons/steve.png'
import Terry from '../../../images/stockIcons/terry.png'
import ToonLink from '../../../images/stockIcons/toon_link.png'
import Villager from '../../../images/stockIcons/villager.png'
import Wario from '../../../images/stockIcons/wario.png'
import WiiFitTrainer from '../../../images/stockIcons/wii_fit_trainer.png'
import Wolf from '../../../images/stockIcons/wolf.png'
import Yoshi from '../../../images/stockIcons/yoshi.png'
import YoungLink from '../../../images/stockIcons/young_link.png'
import Zelda from '../../../images/stockIcons/zelda.png'
import ZeroSuitSamus from '../../../images/stockIcons/zero_suit_samus.png'

let char_index = {
    "fe995f08-d261-47ba-ac69-81bbd272f8ce":	BanjoKazooie,
    "5c63c8cc-6afa-4bd5-ad30-815856cb845d":	Bayonetta,
    "0b325755-b138-4dd6-bbc8-c9604ed0f90c":	Bowser,
    "363d4b63-ba5a-4685-8762-dff02b28266c":	BowserJr,
    "8df1cca7-8810-425b-8f6a-0e65a8eda7a3":	Byleth,
    "b2e680f3-0c62-4387-a4ac-b539d85e90c0":	CaptainFalcon,
    "6be7e121-5b65-405f-9905-6a9279ab199d":	Chrom,
    "d86a3a2b-363d-4fcd-94da-fa7122e9b626":	Cloud,
    "25543733-526a-4d7e-ba50-081597cf28d3":	Corrin,
    "6998259f-982b-47ee-90ea-c1dae66fca5a":	Daisy,
    "7b423052-3d6a-49a7-805b-f4e818792a5c":	DarkPit,
    "65b431e9-84ec-4b85-8a5c-a225e9d40934":	DarkSamus,
    "be1fbfb9-9555-42bb-9e77-7aa2f82c5ded":	DiddyKong,
    "9e7024ec-4a72-4887-bdad-4adbb9b0e3a9":	DonkeyKong,
    "28afc91e-bdfd-45c6-83e9-ff0e9ad52938":	DrMario,
    "759f3e11-0708-4cec-8fcd-f0374ab6aeeb":	DuckHunt,
    "81bb935e-51ad-4a35-92e6-9d7565bed6a9":	Falco,
    "cc43dc32-d6fd-43eb-be16-47c498521272":	Fox,
    "518596af-acc8-4205-b24e-db8f8a953756":	Ganondorf,
    "4dcbb633-58b0-481a-9d6a-c350b2c08640":	Greninja,
    "da0c84d7-9c43-4e74-92e7-113194694b25":	Hero,
    "47c5c078-6112-48ad-9e29-18c6e1077a81":	IceClimbers,
    "b89763e0-222e-472b-a5f8-2de3dba550a7":	Ike,
    "2cf4a409-b186-43a9-948f-175abbef3426":	Incineroar,
    "85c1dc46-460c-453e-ba7a-123ba8e19128":	Inkling,
    "f40fdcb7-a785-4681-8c04-c66cc84edcac":	Isabelle,
    "7a8f391b-e3ca-4c47-8ecb-d7eaa3d605aa":	Jigglypuff,
    "e7d9e195-f86e-4ac8-8bd2-7302889147eb":	Joker,
    "da8fefbf-43a2-47f5-a5bf-d7dadb4fabdc":	Kazuya,
    "bf83ce13-5c43-462b-8baa-c7d1336508ba":	Ken,
    "c0cb713e-e431-4ebe-9dec-dd63bfd2b807":	KingDedede,
    "7f5de07d-9c82-45fb-adae-73c8a2b811e3":	KingKRool,
    "c3a30ee3-52a5-4380-bf5f-a5514890a55f":	Kirby,
    "9f3caa1b-1dbd-49da-b3fd-28dfadd18828":	Link,
    "4d6019ea-9540-4dfe-a6d9-56caa35e7047":	LittleMac,
    "e9128698-7812-4636-9074-127f1983bf1b":	Lucario,
    "3527fd64-64e0-4793-806e-a3656553c7ab":	Lucas,
    "3c6e1beb-0d2a-4387-adad-fa833bf6e3fd":	Lucina,
    "da7df432-47e4-4cbe-9b29-1c7958197105":	Luigi,
    "bcfaf3a7-049c-478f-901f-31ac873260f3":	Mario,
    "d0d93ac1-1681-41df-baa3-7e3968103432":	Marth,
    "5d06d81c-dad5-414b-9e6d-d8c0adeda607":	MegaMan,
    "3e8519a2-faa6-4bcc-98b9-bff3ac95b504":	MetaKnight,
    "fd264bc3-a141-400f-9e55-adabc783322d":	Mewtwo,
    "8bae1c7b-a783-45ee-a246-1ab9507080ba":	MiiBrawler,
    "6a728c16-df81-4513-9896-3222fbce4ea1":	MiiGunner,
    "e3891498-94f6-4ccc-a527-c5f4e26051d1":	MiiSwordfighter,
    "e8962383-ad1a-49ea-ab7a-2c2bf6aabfc1":	MinMin,
    "96b8056f-9f5d-4908-8acf-34353db33d74":	MrGameWatch,
    "d2d68453-454c-41a2-bf12-7b001d4c8f41":	Ness,
    "11d7c49d-8677-4f87-876b-6db94fae00ef":	Olimar,
    "599edd14-687c-4264-812e-bc246f562f31":	PacMan,
    "279e8c96-d915-480d-acbe-98306c958a19":	Palutena,
    "4baddb51-1a48-481b-9c29-332c0f0fa5cb":	Peach,
    "21c5ccdd-d157-42bb-92ac-b99fd07dd247":	Pichu,
    "592fb00b-2943-47de-973a-3a4a5ca56e6a":	Pikachu,
    "3b44eedd-131c-4131-89a1-2f8bc4867e89":	PiranhaPlant,
    "7c869bdb-0101-4aa7-9ea8-82f1d7420928":	Pit,
    "66d89a85-3323-444c-907d-67f7d130c6e6":	PokemonTrainer,
    "295e8672-a7d0-40a1-861f-35097bf0f08b":	PyraMythra,
    "6537aebc-7b92-4b1c-94ca-50ab350a7c46":	Rob,
    "7bf8c562-4dc3-44ce-80ec-cc66f0bb8ff7":	Richter,
    "1874d724-bd91-43ab-91ae-a8b4a740b951":	Ridley,
    "7eb55c9b-4109-4064-a643-cdc809672d7c":	Robin,
    "e2531ffe-e834-49ae-b233-56386472a6ed":	RosalinaLuma,
    "96e48494-946f-4280-a419-488d644135e0":	Roy,
    "42f53983-2889-4f04-9b2f-d2d7b4ef1823":	Ryu,
    "c54fd324-6f7a-46a0-9353-29f80abb8c45":	Samus,
    "788beb66-f7b8-4370-bb75-67afabf4c509":	Sephiroth,
    "0be3828e-571c-44dc-9fc4-cf947b60aad5":	Sheik,
    "6ed721a9-e20a-4d69-8d4f-567ba13cbb29":	Shulk,
    "fa1ac3ba-0880-483b-b94e-9b4952e3f999":	Simon,
    "1aedb3f5-e352-4ebf-b701-7502bbf62366":	Snake,
    "d444e8eb-0140-4e51-9203-b374d9a4431d":	Sonic,
    "8d0093c3-b435-4692-bcc4-5695ade045d7":	Sora,
    "facf1100-a53c-4f9c-bdf3-8612b0df2330":	Steve,
    "5a6be0b7-a734-4507-8999-65130e56e292":	Terry,
    "eea3bb5b-564a-46c4-9c4c-8cef6e59c8b1":	ToonLink,
    "ba94257a-9223-4210-a542-ffd8a798d74c":	Villager,
    "3a80cdac-93bb-4a75-8e38-f69f37d10ec6":	Wario,
    "e6f9ab43-8434-48bb-a765-ebbb97f66fae":	WiiFitTrainer,
    "39f4f717-151a-4cb0-b672-7a66ba3c5274":	Wolf,
    "5d4c8535-fc65-4ebd-9c13-4a96ecd3e94d":	Yoshi,
    "d65e8fc2-a593-4fef-bead-408a967fbb1e":	YoungLink,
    "4c8513b8-a7c7-42f4-a4d4-55f5415d92fb":	Zelda,
    "7b2f87a9-4631-42d0-9f4a-86a1c3ac4465":	ZeroSuitSamus
}

const StockIcon = ({character_id, cssClass, ...props}) => {
    return(
        <img src={char_index[character_id]} className={cssClass} alt="character icon"/>
    )
}

export {StockIcon}