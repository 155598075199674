import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';

import { createAlert } from 'ducks/alertsSlice';

/**
 * 
 * @returns [domElementToPhotographRef, copyDomElementToClipboardFunction]
 */
export const useCopyImage = () => {
    const domElementToPhotographRef = useRef(null)
    const dispatch = useDispatch()

    const copyDomElementToClipboardFunction = useCallback(async (success_message = "Copied to clip board", error_message = "Copy Failed", id_override = null, fileNameForShare="amiibots.png") => {
        let domElementToPhotograph = null
        //Can pass the id of the html element instead of messing with react ref if needed
        if (id_override) {
            document.getElementById(id_override)
        } else {
            domElementToPhotograph = domElementToPhotographRef.current
        }

        if (domElementToPhotograph) {
            const oldClassNames = domElementToPhotograph.className
            domElementToPhotograph.className = oldClassNames + ' html2canvas'
            const canvas = await html2canvas(domElementToPhotograph, {backgroundColor: null
                , useCORS: true
                , logging: false
            })
            canvas.toBlob(async (blob) => {
                //If the OS has a built in share functionality
                try {
                    if (navigator.share) {
                        const filesArray = [new File( [blob], fileNameForShare, {
                            type: blob.type
                            , lastModified: new Date().getTime()
                        })];

                        const shareData = {
                            files: filesArray
                        };

                        navigator.share(shareData);
                    } else {
                        //Copy to clipboard instead
                        await navigator.clipboard.write([
                            new ClipboardItem(
                                Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                                })
                            )
                        ])
                    }

                    dispatch(createAlert({
                        type: "SUCCESS"
                        , message: success_message
                    }))

                } catch (e) {
                    console.error(e)
                    dispatch(createAlert({
                        type: "ERROR"
                        , message: error_message
                    }))
                } finally {
                    domElementToPhotograph.className = oldClassNames
                }
            })
        }

    }, [domElementToPhotographRef.current])

    return [domElementToPhotographRef, copyDomElementToClipboardFunction]
}