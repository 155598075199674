import React, { useState } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

import { useGetPublicRulesets } from 'api/RulesetApi';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetTierLists, useGetTierListByMonth } from 'api/TierListApi';
import { TierListIcon } from './components/tierListIcon/tierListIcon';
import { useCopyImage } from '../../custom-hooks/use-html2canvas';



let TierListPage = (props) => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search)
    const rulesetIdParam = params.get("rulesetId");
    const tierListDateParam = params.get("tierListDate");


    const [rulesetList, setRulesetList] = useGetPublicRulesets();
    const [tierLists, setTierLists] = useGetTierLists();

    const [rulesetId, setRulesetId] = useState(rulesetIdParam ?? "44748ebb-e2f3-4157-90ec-029e26087ad0"); // Vanilla
    const [tierListDate, setTierListDate] = useState(tierListDateParam === "undefined" | tierListDateParam === "null" ? tierLists?.at(0)?.created_at : tierListDateParam);
    const [tierList, setTierList] = useGetTierListByMonth(rulesetId, tierListDate);


    const [ref, copyTierList] = useCopyImage()

    const handleDropdownChange = event => {
        const params = new URLSearchParams({tierListDate, rulesetId})
        if (event.target.name == "rulesetId") {
            setRulesetId(event.target.value);
            params.set("rulesetId", event.target.value);
        }
        else if (event.target.name == "tierListDate") {
            console.log(event.target.value)
            setTierListDate(event.target.value);
            params.set("tierListDate", event.target.value);
        }
        history.replace({pathname: pathname, search: params.toString()})
    }

    if (!rulesetList || !tierLists || !tierList) {
        return <React.Fragment />
    }

    return (
        <React.Fragment>
        <div className='tier-list-header'>
            <h2>Monthly Tier List</h2>
            <select name="rulesetId" defaultValue={rulesetId} onChange={handleDropdownChange}>
                {rulesetList.map((ruleset, i) => (
                    <option key={ruleset.id} value={ruleset.id}>
                        {ruleset.name}
                    </option>
                ))
                }
            </select>
            <select name="tierListDate" defaultValue={tierListDate} onChange={handleDropdownChange}>
                {tierLists.map((tList, i) => (
                    <option key={tList.id} value={moment.utc(tList.created_at).format("YYYY-MM-DD")}>
                        {moment.utc(tList.created_at).format("YYYY-MM")}
                    </option>
                ))
                }
            </select>
            <button onClick={() => copyTierList("Copied Tier List to clip board")}><FontAwesomeIcon icon={faCopy} /></button>
        </div>
        <div id="tier-list" ref={ref} className='tier-list'>
            {tierList.tiers.map((tier, i) => (
                <div key={i} className='tier'>
                    <h3 className='tier-letter'>
                        {tier.name}
                    </h3>
                    <div className='character-list'>
                        {tier.character_placements.map((char, i) => (
                            <TierListIcon character={char} key={i} />
                        ))}
                    </div>
                </div>
            ))
            }
            <div className='watermark'>
                {tierListDate ? moment.utc(tierListDate).format("MMMM YYYY") : moment.utc(tierLists?.at(0)?.created_at).format("MMMM YYYY")} <br />
                {rulesetList?.find((ruleset)=>{return ruleset.id == rulesetId}).name} <br />
                https://www.amiibots.com/tier_list
            </div>
        </div>
        </React.Fragment>
    )

}

export {TierListPage}