import React, { useState, useMemo }  from 'react';

import { BarChart, Bar, Cell, XAxis, YAxis, ReferenceLine, Tooltip, LabelList, ResponsiveContainer, Label } from 'recharts';

import { useGetPlayableCharacters } from 'api/UtilityApi';

const MatchupChart = ({matches, amiibo_id, ...props}) => {
    const [characters, setCharacters] = useGetPlayableCharacters();
    const [matchupFilter, setMatchupFilter] = useState(3);

    const data = useMemo(() => {
        // first gets number of wins/total_matches against each char_id
        const winAndTotalMatchCountByOpponentCharacterIdMap = matches.reduce((winAndTotalMatchCountByOpponentCharacterIdMap, match) => {
            let enemy;
            let win;
            if (match.winner_info.id === amiibo_id) {
                enemy = match.loser_info.character_id;
                win = true
            }
            else {
                enemy = match.winner_info.character_id;
                win = false
            }
            if (!(enemy in winAndTotalMatchCountByOpponentCharacterIdMap)) {
                winAndTotalMatchCountByOpponentCharacterIdMap[enemy] = {win: 0, total: 0};
            }
            if (win) {
                winAndTotalMatchCountByOpponentCharacterIdMap[enemy].win += 1;
            }
            winAndTotalMatchCountByOpponentCharacterIdMap[enemy].total += 1;

            return winAndTotalMatchCountByOpponentCharacterIdMap
        }, {})

        //second half could be it's own use memo so the first half doesn't run when the filter changes, but not a huge deal

        // then converts char_id to character and calcs win_percent
        const data = Object.keys(winAndTotalMatchCountByOpponentCharacterIdMap).map((char_id) => {
            const totalMatchCount = winAndTotalMatchCountByOpponentCharacterIdMap[char_id].total
            const wonMatchCount = winAndTotalMatchCountByOpponentCharacterIdMap[char_id].win
            if (totalMatchCount >= matchupFilter) {
                let name = characters?.find((character)=>{return character.id == char_id})?.name;
                let win_percent = wonMatchCount / totalMatchCount * 100;
                return{
                    "name": (name ?? char_id),
                    "win_percent": win_percent,
                    "bar_label": `${(name ?? char_id)}: ${win_percent.toFixed(2)}%`
                }
            } else {
                return null
            }
        }).filter((dataPoint) => (dataPoint ?? false))

        data.sort((d, a) => a.win_percent - d.win_percent)
        return data
    }, [matches, matchupFilter, characters]);

    // computes gradient value
    // 100 = #1FE200
    // 0 = #E21F00
    const computeColor = (entry) => {
        let num = Math.floor(entry.win_percent);
        let r = parseInt("e2", 16) - (num*2);
        let g = parseInt("1f", 16) + (num*2);
        let color = (r << 16 | g << 8).toString(16);
        return "#" + color;
    }

    const handleFilterChange = event => {
        setMatchupFilter(event.target.value);
    }

    const shouldHideLabels = data.length <= 20

    if (data.length) {
        return (
            <div className={"chart " + props.class_name}>
                <h3>Matchup Chart</h3>
                <div className="chart-filter">
                    <label htmlFor="matchCountFilter">Filter Match Count:</label>
                    <br />
                    <input id="matchCountFilter" defaultValue={matchupFilter} type="number" min="1" onChange={handleFilterChange}></input>
                </div>
                <ResponsiveContainer width="90%" minHeight={`500px`}>
                    <BarChart data={data} barSize={26} layout="vertical" margin={{ top: 20, right: 5, left: 5, bottom: 20 }}>
                        <Bar dataKey="win_percent" fill="#8884d8">
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={computeColor(entry)} />
                                ))
                            }
                            <LabelList dataKey="bar_label" position="insideLeft" style={{ fill: "white" }} formatter={label => shouldHideLabels ? label : ""} />
                        </Bar>
                        <ReferenceLine x={50} stroke="#8884d8" strokeDasharray="20 3" />
                        <XAxis tick={{fill: "white"}} type="number">{!shouldHideLabels ? <Label value="Hover to see names and %'s" position="insideBottom" offset={-20}/> : null}</XAxis>
                        <YAxis  hide={true} type="category" dataKey="name" />
                        <Tooltip labelStyle={{color: "black"}} formatter={(value)=>`${value.toFixed(2)}%`} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }
    else {
        return (
            <div className={"chart " + props.class_name}>
                <h3>Matchup Chart</h3>
                <div className="chart-filter">
                    <label htmlFor="matchCountFilter" >Filter Match Count:</label>
                    <br />
                    <input id="matchCountFilter" defaultValue={matchupFilter} type="number" min="1" onChange={handleFilterChange}></input>
                </div>
                <h2>No data found under this filter</h2>
            </div>
        )
    }
};

export default MatchupChart;