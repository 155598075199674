import React, { useState } from 'react';

function FileUploadCard(props) {

    const [files, setFiles] = useState([])
    const onChangeHandler = (event) => {
        const amiiboTextFile = event.target.files;
        setFiles([...amiiboTextFile])
    };

    const onSubmitHandler = () => {
        if (files.length > 0){
            console.log('File: ', files[0])
            props.fileUploadFunction(files[0])
        }
    }

    let acceptedFileTypes = ".txt"
    if (props.acceptedFileTypes) {
        acceptedFileTypes = props.acceptedFileTypes;
    }

    return (
        <div className="file-upload-card">
            <header>
                {props.title}
            </header>
            <section>
                {props.bodyText}
            </section>
            <footer>
                <input type="file" id="file" name="file" accept={acceptedFileTypes} onChange={onChangeHandler}/>
                <button onClick={onSubmitHandler}>Submit</button>
            </footer>
        </div>
    );
}


export default FileUploadCard;