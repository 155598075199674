import {useEffect, useState} from 'react';

import API_CONSTANTS from "./ApiConstants";
import axios from 'axios';


export const useGetRulesets = () => {
    const [rulesets, setRulesets] = useState(); 

    useEffect(() => {
        (async () => {
            const response = await getRulesets()
            setRulesets(response?.data?.data)
        })()
    }, []);

    return [rulesets, setRulesets];
}

export const getRulesets = async () => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.GET_USER_RULESETS.path
        , method: API_CONSTANTS.GET_USER_RULESETS.method
    })
}


export const useGetPublicRulesets = () => {
    const [rulesets, setRulesets] = useState(); 

    useEffect(() => {
        (async () => {
            const response = await getPublicRulesets()
            setRulesets(response?.data?.data)
        })()
    }, []);

    return [rulesets, setRulesets];
}

export const getPublicRulesets = async () => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.GET_PUBLIC_RULESETS.path
        , method: API_CONSTANTS.GET_PUBLIC_RULESETS.method
    })
}