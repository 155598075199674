import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

import LeaderboardCard from './components/leaderboardCard/leaderboardCard.js';
import { PaginateNavigation } from '../../components/paginateNavigation/PaginateNavigation.js';
import { useGetTopInfoByCharId } from 'api/AmiiboApi';
import { useGetPublicRulesets } from 'api/RulesetApi';
import { useGetAllSpiritSkills } from 'api/SpiritSkillApi';
import { useGetPlayableCharacters } from 'api/UtilityApi';
import { useHistory, useLocation } from 'react-router-dom';
import { useCopyImage } from '../../custom-hooks/use-html2canvas.js';

let Leaderboard = (props) => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search)
    const characterIdParam = params.get("characterId");
    const rulesetIdParam = params.get("rulesetId");

    const [characterList, setCharacterList] = useGetPlayableCharacters();
    const [rulesetList, setRulesetList] = useGetPublicRulesets();
    const [spiritList, setSpiritList] = useGetAllSpiritSkills();
    const [rulesetId, setRulesetId] = useState(rulesetIdParam ?? "44748ebb-e2f3-4157-90ec-029e26087ad0"); // Vanilla
    const [characterId, setCharacterId] = useState(characterIdParam == "null" ? null : characterIdParam); // null results in Overall
    const {amiibo, setAmiibo, cursor, setCursor, pageNum, setPageNum, nextPage, prevPage} = useGetTopInfoByCharId(characterId, rulesetId);

    const [leaderboardRef, copyLeaderboard] = useCopyImage()


    var num_per_page;
    if (characterId === null) {
        num_per_page = 15;
    }
    else {
        num_per_page = 10;
    }

    const handleDropdownChange = event => {
        const params = new URLSearchParams({characterId, rulesetId})
        setCursor(null);
        if(event.target.name == "characterId"){
            if (event.target.value === 'Overall') {
                setCharacterId(null);
                params.delete("characterId");
            } else {
                setCharacterId(event.target.value);
                params.set("characterId", event.target.value);
            }
        } else if (event.target.name == "rulesetId") {
            setRulesetId(event.target.value);
            params.set("rulesetId", event.target.value);
        }
        history.replace({pathname: pathname, search: params.toString()})
    }

    const handleNextClick = event => {
        nextPage();
    }

    const handlePrevClick = event => {
        prevPage();
    }

    if (!amiibo || !rulesetList || !spiritList) {
        return <React.Fragment />
    }

    let spiritNameList = spiritList.data.data.sort(function(a, b) {
        var x = a["id"]; var y = b["id"];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    return (
        <div className='leaderboard-main' ref={leaderboardRef}>
            <header>
                <h1>Leaderboard</h1>
                <h3>NOTE: For an amiibo to be ranked, it must be on 'Active' or 'Standby' and have played enough matches.</h3>
                <div>
                    <select name="characterId" defaultValue={characterId} onChange={handleDropdownChange}>
                        <option>
                            Overall
                        </option>
                        {characterList.map((character, i) => (
                            <option key={character.id} value={character.id}>
                                {character.name}
                            </option>
                        ))
                        }
                    </select>
                    <select name="rulesetId" defaultValue={rulesetId} onChange={handleDropdownChange}>
                        {rulesetList.map((ruleset, i) => (
                            <option key={ruleset.id} value={ruleset.id}>
                                {ruleset.name}
                            </option>
                        ))
                        }
                    </select>
                    <button onClick={copyLeaderboard}><FontAwesomeIcon icon={faCopy} /></button>
                    <hr />
                    <PaginateNavigation
                        currPage={pageNum}
                        totalPages={amiibo.pagination.total_pages}
                        handlePrevClickFunc={handlePrevClick}
                        handleNextClickFunc={handleNextClick}
                    />
                </div>
            </header>
            {amiibo.data.map((amiibo, i) => (
                <LeaderboardCard
                key={amiibo.id} 
                name={amiibo.name}
                character_id={amiibo.playable_character_id}
                trainer={amiibo.user?.twitch_user_name}
                attack_stat={amiibo.attack_stat}
                defense_stat={amiibo.defense_stat}
                spirit_skill_ids={amiibo.spirit_skill_ids}
                rating={amiibo.rating}
                wins={amiibo.wins}
                losses={amiibo.losses}
                total_matches={amiibo.total_matches}
                win_percentage={amiibo.win_percentage}
                placement={Number(i)+1 + pageNum * num_per_page}
                spirit_list={spiritNameList}
                />
        ))}
        {amiibo.data.length == 0 ? <span className='empty-message'>It appears there are no amiibo ranked for this yet. You could be the first!</span> : null}
        </div>
    )
}

export {Leaderboard}