import React, { useState } from "react";

import { useGetSinglesMatchesByAmiiboId } from 'api/SinglesMatchApi';
import { useGetAmiiboByAmiiboId, useGetRankingByAmiiboId } from 'api/AmiiboApi';
import { useGetAllSpiritSkills } from 'api/SpiritSkillApi';

import { StockIcon } from 'components/icons/stockIcon/stockIcon';
import { PaginateNavigation } from '../../components/paginateNavigation/PaginateNavigation.js';

import MiniMatchCard from './components/miniMatchCard/MiniMatchCard';
import RatingChart from "./components/charts/ratingChart";
import MatchupChart from "./components/charts/matchupChart";


const AmiiboInfoPage = ({amiibo_id, props}) => {
    const [matches, setMatches] = useGetSinglesMatchesByAmiiboId(amiibo_id);
    const [amiibo, setAmiibo] = useGetAmiiboByAmiiboId(amiibo_id);
    const [spiritList, setSpiritList] = useGetAllSpiritSkills();
    const [ranking, setRanking] = useGetRankingByAmiiboId(amiibo_id);
    const [pageNum, setPageNum] = useState(0);
    const MATCHES_PER_PAGE = 5;

    if (!matches || !amiibo || !spiritList) {
        return <React.Fragment />
    }


    // For amiibo statistics
    let additionalInfo = [
        {"name": "Win Percentage", "value": (amiibo.win_percentage * 100).toFixed(2) + '%'},
        {"name": "Wins", "value": amiibo.wins},
        {"name": "Losses", "value": amiibo.losses},
        {"name": "Matches", "value": amiibo.total_matches}
    ]

    if (ranking?.overall != null) {
        additionalInfo.push({"name": "Overall Rank", "value": ranking.overall})
    }
    if (ranking?.character != null) {
        additionalInfo.push({"name": "Character Rank", "value": ranking.character})
    }

    // For amiibo spirit info
    let additionalSpiritInfo = []
    if (Number(amiibo.attack_stat) != 0 || Number(amiibo.defense_stat) != 0) {
        additionalSpiritInfo.push({"name": "Attack", "value": amiibo.attack_stat})
        additionalSpiritInfo.push({"name": "Defense", "value": amiibo.defense_stat})
    }

    if (amiibo.spirit_skill_ids != null) {
        amiibo.spirit_skill_ids.map((spiritId, i) => {
            additionalSpiritInfo.push({"name": "Skill " + (i + 1), "value": spiritList?.data?.data?.find((spirit)=>{return spirit.id == spiritId}).name})
        })
    }

    const handleNextClick = event => {
        setPageNum(pageNum + 1);
    }

    const handlePrevClick = event => {
        setPageNum(pageNum - 1);
    }

    return (
        <div className="info-organizer">
            <div className="info-body">
                <div className="info-title">
                    <StockIcon cssClass="info-stock-icon" character_id={amiibo.playable_character_id} />
                    <div>
                        <h2>{amiibo.name}</h2>
                        <span>{amiibo.user.twitch_user_name}</span>
                    </div>
                    <div>
                        <h2>{Number(amiibo.rating).toFixed(2)}</h2>
                        <span>Rating</span>
                    </div>

                </div>
                <div className="info-amiibo-extra">
                    {additionalInfo.map((info, i) =>  { return (
                    <div key={info.name}>
                        <span className="info-amiibo-extra-value">{info.value}</span>
                            <br />
                        <span className="info-amiibo-extra-name">{info.name}</span>
                    </div>
                    )})}
                </div>
                {(additionalSpiritInfo.length) ? (
                    <div className="info-amiibo-extra">
                        {additionalSpiritInfo.map((info, i) =>  { return (
                        <div key={info.name}>
                            <span className="info-amiibo-extra-value">{info.value}</span>
                                <br />
                            <span className="info-amiibo-extra-name">{info.name}</span>
                        </div>
                        )})}
                    </div>
                ) : null}
                <RatingChart matches={matches} amiibo_id={amiibo_id} class_name="info-chart" />
                <MatchupChart matches={matches} amiibo_id={amiibo_id} class_name="info-chart" />
            </div>
            <div className="info-sidebar">
                <div className="match-card-col">
                {matches.slice(pageNum*MATCHES_PER_PAGE, pageNum*MATCHES_PER_PAGE + MATCHES_PER_PAGE).map((match, i) => {

                    return (
                        <MiniMatchCard 
                        key={i}
                        match={match}
                        amiibo_id={amiibo_id}
                        />
                    )
                })}
                </div>
                <PaginateNavigation
                        currPage={pageNum}
                        totalPages={Math.ceil(matches.length / MATCHES_PER_PAGE)}
                        handlePrevClickFunc={handlePrevClick}
                        handleNextClickFunc={handleNextClick}
                    />
            </div>
        </div>
    )
}

export { AmiiboInfoPage }