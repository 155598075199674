import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'ducks/userSlice';


let AccountInfo = (props) => {
    const user = useSelector(selectUser);
    const potatoAmountFormatter = Intl.NumberFormat([], {
        notation: "compact"
        , minimumFractionDigits: 0
        , maximumFractionDigits: 2
    })
    if (user?.id) {
        return (
            <div className="account-info-body">
                <h1>{user.twitch_user_name}</h1>
                
                <div className="tile-container">
                    <div>
                        <h3>Sub Tier</h3>
                        {(user.subscriptions?.filter?.(subscription => subscription.platform === "twitch")?.length === 0) 
                            ? "Not Subscribed" 
                            : Number(user.subscriptions?.filter?.(subscription => subscription.platform === "twitch")[0].subscription_metadata.tier) / 1000
                        }
                    </div>
                    {(user.subscriptions?.filter?.(subscription => subscription.platform !== "twitch")?.length > 0) ? (
                        <div>
                            <h3>Additional Bonuses</h3>
                            {user.subscriptions
                                ?.filter?.(subscription => subscription.platform !== "twitch")
                                ?.reduce((totalWeight, subscription) => {
                                    console.log(`Weight: ${subscription.weight} ${subscription.subscription_metadata.description} Expires ${subscription?.subscription_metadata?.expires ? new Date(subscription.subscription_metadata.expires) : "N/A"}`);
                                    return totalWeight += subscription.weight
                                }, 0)
                            }
                        </div>
                    ) : null}
                    <div>
                        <h3>Discord ID</h3>
                        {(user.discord_id === null) ? "Not Connected" : user.discord_id}
                    </div>
                    <div>
                        <h3>Loyalty Points</h3>
                        {user.loyalty_points}
                    </div>
                    <div>
                        <h3>Potatoes</h3>
                        {potatoAmountFormatter.format(user.potatoes)}
                    </div>
                </div>
                <span>Note: It may take some time for the system to update after subscribing/redeeming loyalty.</span>
            </div>

        )
    }
    else {
        return (
            <React.Fragment />
        )
    }
}

export {AccountInfo}