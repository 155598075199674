import React from "react";

const PopUp = ({content, state, closeFunc, props}) => {
    function onClickListener() {
        closeFunc();
    }

    if (state) {
        return (
            <React.Fragment>
            <div className="popUp" onClick={onClickListener}>
            </div>
            <button autoFocus className="popUp-close" onClick={onClickListener}>x</button>
            <div className="popUp-main">
                {content}
            </div>
            </React.Fragment>
        )
    }
    else {
        return <React.Fragment />
    }
}

export {PopUp}