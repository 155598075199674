import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectAlerts, deleteAlert } from 'ducks/alertsSlice';

import AlertBox from './AlertBox'

function Alerts(props) {

    const dispatch = useDispatch()
    const dispatchDeleteAlert = (...args) => dispatch(deleteAlert(...args));
    const alerts = useSelector(selectAlerts);
    const [timeoutsMap, setTimeoutsMap] = useState({})

    useEffect(()=>{ //Prevent rerenders from creating multiple timeouts
        for (const key in Object.keys(timeoutsMap)){
            if (alerts.findIndex((item)=> item.id == key) == -1) {
                clearTimeout(timeoutsMap[key]);
                delete timeoutsMap[key];
            }
        }
        
        let newTimeoutMap = {...timeoutsMap};
        
        alerts.forEach(alert => {
            if (!(alert.id in newTimeoutMap)){

                // Average reading speed for children is 24wpm, which is 1 word every .4 seconds. 
                // Set default timeout to average reading speed + 10%, plus a base second to start reading the message
                // Need to make sure these auto closing alerts are easily readible for users with disabilities.
                let millisecondsUntilAutoclose = ((alert?.message?.split?.(" ")?.length ?? 0) * 400 * 1.10 + 1000) 
                if (alert?.confirmationCallback || alert.cancellationCallback){
                    millisecondsUntilAutoclose = -1 //Don't allow the alert to auto close if it's an confirm/cancel alert
                } else if (alert?.millisecondsUntilAutoclose != null && alert.millisecondsUntilAutoclose !== undefined){
                    millisecondsUntilAutoclose = alert.millisecondsUntilAutoclose
                } else if (alert.type == "ERROR"){
                    millisecondsUntilAutoclose = -1
                }

                let timeout = null;
                if (millisecondsUntilAutoclose > 0){
                    newTimeoutMap[alert.id] = setTimeout(()=>{dispatchDeleteAlert(alert)}, millisecondsUntilAutoclose);
                }
            }
        });
        setTimeoutsMap(newTimeoutMap);
    }, [alerts]);


    let className = "alerts-container"
    if (props.className) {
        className += " "+props.className
    }
    return (
        <aside className={className}>
            {alerts.map((alert) => {
                const type = alert?.type ? alert.type : "SUCCESS";

                let dismissable = false;
                if (alert?.confirmationCallback || alert.cancellationCallback){
                    dismissable = false;
                } else if (alert?.dismissable != null && alert.dismissable !== undefined) {
                    dismissable = alert.dismissable
                } else if (!(alert.id in timeoutsMap)) {
                    // If the message won't timeout on it's own, make sure it's dismissable
                    dismissable = true;
                }

                let dismissFunction = () => {
                    dispatchDeleteAlert(alert);
                }

                return (<AlertBox 
                    key={alert.id} alert={alert} 
                    dismissable={dismissable} 
                    type={type}
                    confirmationCallback={alert.confirmationCallback}
                    cancellationCallback={alert.cancellationCallback}
                    dismissFunction={dismissFunction} />)
            })}
        </aside>
    );
}

export default Alerts;