import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faFilter, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import SortSubmenu from './SortSubmenu';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

/**
 * Component that handles Sorting, Filtering, Bulk Add
 * @param {*} props 
 */
function SearchParametersBar({ className, children, data, menuConfig, ...props }) {

    const previousData = usePrevious(data)

    className = "search-parameters-card " + className

    const sortMenu = (
        <div key="Sort" className="menu-item">
            <FontAwesomeIcon icon={faSort} /><span>Sort</span>
            <SortSubmenu data={data} previousData={previousData} {...props}/>
        </div>
    )

    const filterMenu = (
        <div key="Filter" className="menu-item">
            <FontAwesomeIcon icon={faFilter} /><span>Filter</span>
            <div className="submenu">Filter SubMenu</div>
        </div>
    )

    let menuItems = []

    if (menuConfig?.sortEnabled !== false){
        menuItems.push(sortMenu)
    }

    if (menuConfig?.filterEnabled !== false){
        menuItems.push(filterMenu)
    }
    
    for (const headerConfig of menuConfig?.customMenus){
        menuItems.push(
            <div key={headerConfig?.title} className="menu-item">
                {headerConfig?.icon}<span>{headerConfig?.title}</span>
                <div className="submenu">{headerConfig?.submenu}</div>
            </div>
        )
    }

    return (
        <div className={className}>
            <header>
                {menuItems}
            </header>
            <section>
                {children}
            </section>
        </div>
    );
}

export default SearchParametersBar;