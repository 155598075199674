import { createSlice } from '@reduxjs/toolkit';
import store from './store.js';

export const alertsSlice = createSlice({
    name: 'alerts'
    , initialState: []
    , reducers : {
        createAlert: (state, action) => {
            action.payload.id = Date.now() +""+ (Math.random() * 16 | 0) //Give a unique id so deleting doesn't rely on index, and thus avoid race conditions
            state.push(action.payload);
        }
        , deleteAlert: (state, action) => {
            const i = state.findIndex((element) => action.payload.id == element.id)
            if (i >= 0) {
                state.splice(i, 1);
            }
        }
    }
})

export const { createAlert, deleteAlert } = alertsSlice.actions;

export const selectAlerts = state => state.alerts;

export default alertsSlice.reducer;