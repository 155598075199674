import React from "react";

const PaginateNavigation = ({currPage, totalPages, handlePrevClickFunc, handleNextClickFunc, props}) => {
    return (
        <div className="pagination">
            <button className="arrow-btn" onClick={handlePrevClickFunc} disabled={currPage == 0}>&lt;</button>
            <span>{totalPages == 0 ? 0 : currPage + 1} / {totalPages}</span>
            <button className="arrow-btn" onClick={handleNextClickFunc} disabled={currPage + 1 >= totalPages}>&gt;</button>
        </div>
    )
}

export {PaginateNavigation}