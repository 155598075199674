import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';


import { setUser } from 'ducks/userSlice';
import API_CONSTANTS from "./ApiConstants";

export const useGetCurrentUser = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const response = await getCurrentUser();
            dispatch(setUser(response?.data?.data))
        })()
    }, [setUser])

}

export const getCurrentUser = async () => {
    return await axios.request({
        url: API_CONSTANTS.USER_INFO_ENDPOINT.path
        , method: API_CONSTANTS.USER_INFO_ENDPOINT.method
    })
}
