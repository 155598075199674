import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { Brush, Line, Area, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, ComposedChart, Bar, Cell } from 'recharts';
import moment from 'moment';


const RatingChart = ({matches, amiibo_id, ...props}) => {
    const [max, setMax] = useState(-Number.MAX_VALUE);

    const data = useMemo(() => {
        if(!matches || matches.length < 1){
            return []
        }

        let tempMax = -Number.MAX_VALUE
        // We are looking at the prematch data for all these except the end, that way it aligns with the opponent better.
        // However, that means the date they got this rating was the previous match's date, so we'll use that for the x axis key
        let previousMatchDate = null

        //Shallow copy the array so that our changes aren't affecting other components using this match data
        const data = [...matches].reverse().map((match) => {
            let amiiboPreMatchInfo;
            let opponentPreMatchInfo;
            let amiiboPostMatchInfo;
            let opponentPostMatchInfo;
            let amiiboDidWin;
            if (match.winner_info.id === amiibo_id) {
                amiiboPostMatchInfo = match.winner_info;
                opponentPostMatchInfo = match.loser_info;
                amiiboDidWin = true;
            }
            else {
                amiiboPostMatchInfo = match.loser_info;
                opponentPostMatchInfo = match.winner_info;
                amiiboDidWin = false;
            }


            if (match.fp1.id === amiibo_id) {
                amiiboPreMatchInfo = match.fp1;
                opponentPreMatchInfo = match.fp2;
            }
            else {
                amiiboPreMatchInfo = match.fp2;
                opponentPreMatchInfo = match.fp1;
            }

            let mstDate = moment.utc(match.created_at).subtract({hours: 7});
            const ratingAchievedDate = previousMatchDate ?? mstDate
            previousMatchDate = mstDate

            const amiibo_rating = Number(amiiboPreMatchInfo.rating)
            if (amiibo_rating > tempMax) {
                tempMax = amiibo_rating
            }

            return {
                name: ratingAchievedDate.format("MM-YYYY"),
                rating: Number(amiiboPreMatchInfo.rating),
                amiibo: [Number(amiiboPreMatchInfo.rating), Number(amiiboPreMatchInfo.rating_mu)],
                opponent: [Number(opponentPreMatchInfo.rating), Number(opponentPreMatchInfo.rating_mu)],
                amiiboDidWin, 
                amiiboPreMatchInfo,
                amiiboPostMatchInfo,
                opponentPreMatchInfo,
                opponentPostMatchInfo
            }}
        )
        // Add in the final matches post match data. No opponent will be on this one, so it's much more simplified
        const lastMatchDataPoint = data[data.length-1]
        data.push({
            name: previousMatchDate.format("MM-YYYY"),
            rating: Number(lastMatchDataPoint.amiiboPostMatchInfo.rating),
            amiibo: [Number(lastMatchDataPoint.amiiboPostMatchInfo.rating), Number(lastMatchDataPoint.amiiboPostMatchInfo.rating_mu)]
        })

        const amiibo_rating = Number(lastMatchDataPoint.amiiboPostMatchInfo.rating)
        if (amiibo_rating > tempMax) {
            tempMax = amiibo_rating
        }
        setMax(tempMax)

        return data
    }, [matches])
    
    useEffect(() => {
        if (localStorage.getItem("rating_chart_advanced") === null) {
            localStorage.setItem('rating_chart_advanced', false);
        }
    }, []);

    const [isAdvancedGraph, setIsAdvancedGraph] = useState(JSON.parse(localStorage.getItem('rating_chart_advanced')))

    const toggleIsAdvancedGraph = useCallback(() => {
        const newValue = !isAdvancedGraph
        setIsAdvancedGraph(newValue)
        localStorage.setItem('rating_chart_advanced', newValue);
    }, [setIsAdvancedGraph, isAdvancedGraph])

    //Simple
    const graphConfiguration = {
        children: <Line type="monotone" dataKey="rating" stroke="#8884d8" strokeWidth={2} />,
        tooltipProps: {
            labelStyle: {color: "black"},
            formatter: (value, name, props) => value.toFixed(2),
        }
    }
    if (isAdvancedGraph){
        graphConfiguration.children = (
            <>
                <Bar dataKey="opponent" fill="red">
                    {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry?.amiiboDidWin ? "green":"red"}/>)}
                </Bar>
                <Area dataKey="amiibo" stroke="#8884d8" fill="#8884d8" />
            </>
        );

        graphConfiguration.tooltipProps = {
            ...graphConfiguration.tooltipProps,
            labelFormatter: (label)=>`${label}: Rating (Rating Mu)`,
            formatter: (value, name, props)=>`${value[0].toFixed(2)} (${value[1].toFixed(2)})`,
            itemSorter: (input)=>input.name,
        }
    }


    if (data.length) {
        return (
            <div className={"chart " + props.class_name}>
                <h3>Rating History</h3>
                <ResponsiveContainer width="90%" height="100%" maxHeight="400px" minHeight="400px">
                    <ComposedChart data={data} margin={{ top: 5, right: 5, left: 20, bottom: 5 }}>
                        {graphConfiguration.children}
                        <ReferenceLine y={max} stroke="#8884d8" strokeDasharray="20 3" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis tick={{fill: "white"}} dataKey="name" />
                        <YAxis tick={{fill: "white"}}  />
                        <Tooltip {...graphConfiguration.tooltipProps} allowEscapeViewBox={{y: true}} />
                        <Brush dataKey="name" height={30} stroke="#8884d8" />
                    </ComposedChart>
                </ResponsiveContainer>
                <label>
                    <input type="checkbox" checked={isAdvancedGraph} onChange={toggleIsAdvancedGraph} />
                    Advanced Graph
                </label>
            </div>
        )
    }
    else {
        return (
            <div className={"chart " + props.class_name}>
                <h2>This amiibo has no matches</h2>
            </div>
        )
    }
};

export default RatingChart;