import React, { useEffect, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from 'components/tag/Tag';

/**
 * Component that handles Sorting, Filtering, Bulk Add
 * @param {*} props 
 */
function SortSubmenu({ data=[], updateData=()=>{}, sortConfig, previousData=[], ...props }) {

    const [newSortCriteria, setNewSortCriteria] = useState({"asc": true})
    const [originalAttributes, setOriginalAttributes] = useState([])
    const [usedAttributes, setUsedAttributes] = useState(new Set())
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [sortList, setSortList] = useState([]);

    // Initialize the default sorting
    useEffect(() => {
        if (sortConfig?.default){
            setSortList(sortConfig?.default)
            let newUsedAttributes = new Set()
            for(const sortObject of sortConfig?.default){
                newUsedAttributes.add(sortObject.value)
            }
            setUsedAttributes(newUsedAttributes)
        }
    }, [sortConfig?.default])

    // Populate Selectable Attributes to sort by
    useEffect(() => {
        if (originalAttributes?.length == 0){
            if(sortConfig?.availableAttributes){
                setOriginalAttributes(sortConfig.availableAttributes)
            } else if(data?.length > 0){
                const keys = Object.keys(data[0])
                let newOriginalAttributes = []
                for(const key of keys){
                    newOriginalAttributes.push({"key": key, "value": key})
                }
                setOriginalAttributes(newOriginalAttributes)
            }
        }

        let newAvailableAttributes = []
        for(const attribute of originalAttributes){
            if (!usedAttributes.has(attribute.value)){
                newAvailableAttributes.push(attribute)
            }
        }
        setAvailableAttributes(newAvailableAttributes)
        
    }, [originalAttributes, usedAttributes, data])


    // Handle Sorting of data
    useEffect(()=>{
        let dataCopy = [...data]
        dataCopy.sort((a, b)=>{
            for (const sortObject of sortList) {
                let aSortValue = a[sortObject.value];
                let bSortValue = b[sortObject.value];
                // If there are sort overrides, replace the sort value with the provided mapping
                if(sortObject.value in sortConfig.sortOrderOverrides){
                    // If value isn't found for override, default to -1. Will cause issues if we sort by strings instead, but something to worry about later
                    aSortValue = aSortValue in sortConfig.sortOrderOverrides[sortObject.value] ? sortConfig.sortOrderOverrides[sortObject.value][aSortValue] : -1;
                    bSortValue = bSortValue in sortConfig.sortOrderOverrides[sortObject.value] ? sortConfig.sortOrderOverrides[sortObject.value][bSortValue] : -1;
                }

                const aIsLessThanB = (sortObject.asc && aSortValue < bSortValue)
                    || (!sortObject.asc && aSortValue > bSortValue);

                const bIsLessThanA = (sortObject.asc && aSortValue > bSortValue)
                    || (!sortObject.asc && aSortValue < bSortValue);

                if(aIsLessThanB){
                    return -1;
                } else if (bIsLessThanA){
                    return 1;
                }
            }
            return 0;
        })

        let shouldUpdateDataOrder = dataCopy?.length != previousData?.length;

        if (shouldUpdateDataOrder){
            updateData(dataCopy);
            return
        } 

        for(let i = 0; i < dataCopy.length; i++){
            shouldUpdateDataOrder = dataCopy[i] != previousData[i]
            if (shouldUpdateDataOrder){
                updateData(dataCopy);
                return
            } 
        }
    }, [data, sortList, sortConfig?.sortOrderOverrides])

    const onDropdownChange = useCallback((event)=>{
        const attribute = event.target.name;
        let value = event.target.value;
        if(value == "true"){
            value = true;
        } else if (value == "false"){
            value = false;
        }
        const selectedIndex = event.target.selectedIndex;
        const key = event.target[selectedIndex].text;
        let newSortCriteriaCopy = Object.assign({}, newSortCriteria, {[attribute]: value});
        newSortCriteriaCopy;
        if (attribute == "value"){
            newSortCriteriaCopy.key = key;
        }
        setNewSortCriteria(newSortCriteriaCopy);
    }, [newSortCriteria])

    const onSubmit = useCallback(()=>{
        const newSortCriteriaIsValid = newSortCriteria?.value && newSortCriteria?.hasOwnProperty("asc") && newSortCriteria?.key
        if (newSortCriteriaIsValid){
            addSortItem(newSortCriteria)
        }
    }, [newSortCriteria])

    const addSortItem = (newItem) => {
        let newSortList = [...sortList];
        newSortList.push(newItem)
        setSortList(newSortList);
        let newUsedAttributes = new Set(usedAttributes)
        newUsedAttributes.add(newItem.value)
        setUsedAttributes(newUsedAttributes)
    }

    const removeSortItem = (indexToDelete) => {
        let newSortList = [...sortList];
        const deletedItems = newSortList.splice(indexToDelete, 1)
        setSortList(newSortList);
        let newUsedAttributes = new Set(usedAttributes)
        newUsedAttributes.delete(deletedItems[0].value)
        setUsedAttributes(newUsedAttributes)
    }

    return (
        <div className="submenu">
            <form className="sorting-form" onSubmit={()=>{}}>
                <select name="value" onChange={onDropdownChange}>
                    <option hidden disabled selected value> -- Select Attribute -- </option>
                    {availableAttributes?.map((attributeObject)=>{
                        return <option key={attributeObject.value} value={attributeObject.value}>{attributeObject.key}</option>
                    })}
                </select>
                <select name="asc" onChange={onDropdownChange}>
                    <option value={true}>Ascending</option>
                    <option value={false}>Descending</option>
                </select>
                <button onClick={onSubmit} type="button">Add Sort Criteria</button>
            </form>
            <div className="tag-container">
                {sortList.map((sortObject, i)=> {
                    return <Tag key={sortObject.value} onCloseCallback={()=>{removeSortItem(i)}}>{sortObject.key} {(sortObject.asc) ? "Asc" : "Desc"}</Tag>
                })}
            </div>
        </div>
    );
}

export default SortSubmenu;