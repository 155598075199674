import React from 'react';

import {StockIcon} from 'components/icons/stockIcon/stockIcon';

let TierListIcon = ({character, ...props}) => {

    let class_name = 'character';
    
    if (character.sample_size <= 10) {
        class_name += ' high-variance';
    }


    return (
        <div className={class_name} title={character.playable_character.character_name}>
            <StockIcon cssClass="char-image" character_id={character.playable_character.id} />
            <div>{Number(character.mean).toFixed(2)}</div>
        </div>
    )
}

export {TierListIcon}