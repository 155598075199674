import React from 'react';
import {StockIcon} from 'components/icons/stockIcon/stockIcon';


const MatchCard = ({match, user_id, show_result, ...props}) => {
    let match_result = "";
    if (match.winner_info.trainer_id === match.loser_info.trainer_id || !show_result) {
        // pass
        // leave neutral if trainer facted themselves or result shouldn't be shown
    }
    else if (match.winner_info.trainer_id === user_id) {
        match_result += " winner";
    }
    else if (match.loser_info.trainer_id === user_id) {
        match_result += " loser";
    }

    let fp1_rating = Number(match.fp1.rating).toFixed(2);
    if (match.fp1.rating_sigma >= 2.5) {
        fp1_rating = "Unrated";
    }
    let fp2_rating = Number(match.fp2.rating).toFixed(2);
    if (match.fp2.rating_sigma >= 2.5) {
        fp2_rating = "Unrated";
    }
    
    return (
        <div className={"match-card" + match_result}>
            <div className="card-header">
                <span className="ruleset">{props.ruleset_name}</span>
                <a href={props.match_url}>{props.date}</a>
            </div>
            <div className={"card-body" + match_result}>
                <div className="card-body-info">
                    <span className="trainer-name">{match.fp1.trainer_name}</span>
                    <StockIcon cssClass="stock-icon" character_id={match.fp1.character_id} />
                    {match.fp1.name}
                    <div>({fp1_rating})</div>
                </div>
                <div className="card-body-info">
                    <span className="trainer-name">{match.fp2.trainer_name}</span>
                    <StockIcon cssClass="stock-icon" character_id={match.fp2.character_id} />
                    {match.fp2.name}
                    <div>({fp2_rating})</div>
                </div>
            </div>
            <div className="card-footer"> 
                {(match.fp1.id === match.winner_info.id && show_result) ? (
                    <React.Fragment>
                        <span>{Number(match.winner_info.rating_change).toFixed(2)} W</span>
                        <span>{Number(match.loser_info.rating_change).toFixed(2)} L</span>
                    </React.Fragment>
                ) : null}

                {(match.fp2.id === match.winner_info.id && show_result) ? (
                    <React.Fragment>
                        <span>{Number(match.loser_info.rating_change).toFixed(2)} L</span>
                        <span>{Number(match.winner_info.rating_change).toFixed(2)} W</span>
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    )
};

export default MatchCard;