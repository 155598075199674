import React, {useState} from 'react';
import {StockIcon} from 'components/icons/stockIcon/stockIcon';

const LeaderboardCard = ({name, trainer, rating, placement, ...props}) => {
    const [showInfo, setShowInfo] = useState(false); 

    const handleInfoOnClick = () => {
        setShowInfo(!showInfo)
    }

    let additionalInfo = [
        {"name": "Win Percentage", "value": (props.win_percentage * 100).toFixed(2) + '%'},
        {"name": "Wins", "value": props.wins},
        {"name": "Losses", "value": props.losses},
        {"name": "Matches", "value": props.total_matches}
    ]

    if (Number(props.attack_stat) != 0 || Number(props.defense_stat) != 0) {
        additionalInfo = additionalInfo.concat([
            {"name": "Attack", "value": props.attack_stat},
            {"name": "Defense", "value": props.defense_stat}
        ])
    }

    if (props.spirit_skill_ids != null) {
        props.spirit_skill_ids.map((spiritId, i) => {
            additionalInfo.push({"name": "Skill " + (i + 1), "value": props.spirit_list[spiritId].name})
        })
    }

    let placement_class = "";
    if(placement == 1){
        placement_class = " first"
    } else if (placement == 2){
        placement_class = " second"
    } else if (placement == 3){
        placement_class = " third"
    } else if (placement <= 10){
        placement_class = " top-ten"
    }
    
    return (
        <div className='leaderboard-card'>
            <button className='card-main' onClick={handleInfoOnClick}>
                <div className='card-left'>
                    <h1>{placement + "."}</h1>
                    <div>
                        <h3>{name}</h3>
                        <span className="trainer-name">{trainer}</span>
                    </div>
                    <StockIcon cssClass="card-stock-icon" character_id={props.character_id} />
                </div>
                <div className={'card-right'+placement_class}>
                    <h2>
                        {rating.toFixed(2)}
                    </h2>
                </div>
            </button>
            {(showInfo) ? (
            <div className={'card-extra'+placement_class}>
                {additionalInfo.map((info, i) =>  { return (
                <div key={info.name} className="card-extra-info">
                    <span className="card-extra-value">{info.value}</span>
                        <br />
                    <span className="card-extra-name">{info.name}</span>
                </div>
                )})}
            </div>
            ) : null}
        </div>
    )
};

export default LeaderboardCard;
