import {useEffect, useState} from 'react';
import { saveAs } from 'file-saver';

import API_CONSTANTS from "./ApiConstants";
import axios from 'axios';


export const useGetAmiiboByUserId = (user_id) => {
    const [amiibo, setAmiibo] = useState(); 

    useEffect(() => {
        if(user_id){
            (async () => {
                const response = await getAmiiboByUserId(user_id)
                setAmiibo(response?.data?.data)
            })()
        }
    }, [user_id]);

    return [amiibo, setAmiibo];
}

export const getAmiiboByUserId = async (user_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.AMIIBO_BY_USER_ID_ENDPOINT.path + user_id
        , method: API_CONSTANTS.AMIIBO_BY_USER_ID_ENDPOINT.method
    })
}

export const useGetTopInfoByCharId = (charId, rulesetId) => {
    const [amiibo, setAmiibo] = useState();
    const [cursor, setCursor] = useState(null);
    const [pageNum, setPageNum] = useState(0);
    const [isNext, setIsNext ] = useState(true); // used to help prevent pageNum fromm being offset

    useEffect(() => {
        if (cursor === null) {
            setPageNum(0);
        }
        else if (isNext) {
            setPageNum(pageNum + 1);
        }
        else {
            setPageNum(pageNum - 1);
        }
        
    }, [cursor])


    useEffect(() => {
        if(rulesetId){
            (async () => {
                const response = await getTopInfoByCharId(charId, rulesetId, cursor)
                setAmiibo(response?.data)
            })()
        }
    }, [charId, rulesetId, cursor]);

    const nextPage = () => {
        setIsNext(true);
        setCursor(amiibo.pagination.cursor.next);
    }

    const prevPage = () => {
        setIsNext(false);
        setCursor(amiibo.pagination.cursor.previous);
    }


    return {amiibo, setAmiibo, cursor, setCursor, pageNum, setPageNum, nextPage, prevPage};
}

export const getTopInfoByCharId = async (playable_character_id, ruleset_id, cursor) => {
    let query = {playable_character_id, ruleset_id, matchmaking_status: "STANDBY,ACTIVE", cursor};
    if (playable_character_id != null) {
        query.per_page = 10;
    }
    else {
        query.per_page = 15;
    }
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.TOP_BY_CHAR_RULESET_ID_ENDPOINT.path
        , method: API_CONSTANTS.TOP_BY_CHAR_RULESET_ID_ENDPOINT.method
        , params: query
    })
}

export const postAmiibo = async (newAmiiboBin, rulesetId) => {
    let data = new FormData();
    data.append('amiibo', newAmiiboBin);

    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.REGISTER_AMIIBO_ENDPOINT.path
        , method: API_CONSTANTS.REGISTER_AMIIBO_ENDPOINT.method
        , params: {ruleset_id: rulesetId}
        , data
    })
};

export const useGetAmiiboByAmiiboId = (amiibo_id) => {
    const [amiibo, setAmiibo] = useState(); 

    useEffect(() => {
        if(amiibo_id){
            (async () => {
                const response = await getAmiiboByAmiiboId(amiibo_id)
                setAmiibo(response?.data?.data)
            })()
        }
    }, [amiibo_id]);

    return [amiibo, setAmiibo];
}

export const getAmiiboByAmiiboId = async (amiibo_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.AMIIBO_BY_AMIIBO_ID_ENDPOINT.path + amiibo_id
        , method: API_CONSTANTS.AMIIBO_BY_AMIIBO_ID_ENDPOINT.method
    })
}

export const useGetRankingByAmiiboId = (amiibo_id) => {
    const [ranking, setRanking] = useState(); 

    useEffect(() => {
        if(amiibo_id){
            (async () => {
                const response = await getRankingByAmiiboId(amiibo_id)
                setRanking(response?.data?.data)
            })()
        }
    }, [amiibo_id]);

    return [ranking, setRanking];
}

export const getRankingByAmiiboId = async (amiibo_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.RANKING_BY_AMIIBO_ID_ENDPOINT.path + amiibo_id
        , method: API_CONSTANTS.RANKING_BY_AMIIBO_ID_ENDPOINT.method
    })
}


// For updating the amiibo's bin
export const putAmiiboBin = async (updatedAmiiboBin, amiiboId) => {
    let data = new FormData();
    data.append('amiibo', updatedAmiiboBin);

    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.UPDATE_AMIIBO_ENDPOINT.path  + amiiboId
        , method: API_CONSTANTS.UPDATE_AMIIBO_ENDPOINT.method
        , data
    })
};

// For updating everything except the bin
export const putAmiibo = async (amiibo) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.UPDATE_AMIIBO_ENDPOINT.path  + amiibo.id
        , method: API_CONSTANTS.UPDATE_AMIIBO_ENDPOINT.method
        , data: amiibo
    })
};

export const deleteAmiibo = async (amiibo) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.DELETE_AMIIBO_ENDPOINT.path  + amiibo.id
        , method: API_CONSTANTS.DELETE_AMIIBO_ENDPOINT.method
        //, headers: {'Content-Type': 'application/json'}
    })
};

export const postAmiiboBinConversionFromNfcTools = async (textFile) => {
    let data = new FormData();
    data.append('file', textFile);
    const response = await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.CONVERT_NFC_TOOLS_EXPORT_TO_BIN_ENDPOINT.path
        , method: API_CONSTANTS.CONVERT_NFC_TOOLS_EXPORT_TO_BIN_ENDPOINT.method
        , data
        , responseType: 'blob',
    })

    if (response.headers["content-type"] == "application/octet-stream"){
        saveAs(response.data, response.headers["content-disposition"].substring(response.headers["content-disposition"].indexOf("=")+1));
    }
};

export const getAmiiboBin = async (amiibo_id) => {
    const response = await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.AMIIBO_BIN_ENDPOINT.path + amiibo_id
        , method: API_CONSTANTS.AMIIBO_BIN_ENDPOINT.method
        , responseType: 'blob',
    })
    
    if (response.headers["content-type"] == "application/octet-stream"){
        saveAs(response.data, response.headers["content-disposition"].substring(response.headers["content-disposition"].indexOf("=")+1));
    }
};

export const putToSubmissionApp = async (amiibo_id, submissions_callback) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.SUBMIT_AMIIBO_TO_SUBMISSIONS_APP.path  + amiibo_id
        , method: API_CONSTANTS.SUBMIT_AMIIBO_TO_SUBMISSIONS_APP.method
        , data: {submissions_callback}
    })
};

export const putToExternalDomain = async (amiibo_id, external_callback) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.SUBMIT_AMIIBO_TO_EXTERNAL_DOMAIN.path  + amiibo_id
        , method: API_CONSTANTS.SUBMIT_AMIIBO_TO_EXTERNAL_DOMAIN.method
        , data: {external_callback}
    })
};