import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUser } from 'ducks/userSlice';
import DropDownMenu from 'components/dropDownMenu/DropDownMenu';

import API_CONSTANTS from "api/ApiConstants";
import UI_NAVIGATION_CONSTANTS from 'constants/uiNavigationConstants'
import Navigation from "components/navigation/Navigation";


const onLogin = () => {
    const loginUrl = process.env.REACT_APP_LOGIN_HOST + API_CONSTANTS.LOGIN_ENDPOINT.path
    const currentPath = window.location.pathname + window.location.search
    localStorage.setItem('pathBeforeLogin', currentPath)
    window.location.href = loginUrl
    
}

const logOut = () => {
    window.location.href = process.env.REACT_APP_LOGIN_HOST + API_CONSTANTS.LOGOUT_ENDPOINT.path
}

const Header = (props) => {
    const history = useHistory();
    const user = useSelector(selectUser);

    const [displayDropdown, setDisplayDropdown] = useState('false');

    const routeChange = () =>{ 
        // when opening account page, collapse dropdown
        setDisplayDropdown(!displayDropdown)

        let path = UI_NAVIGATION_CONSTANTS.ACCOUNT_INFO;
        history.push(path);
    }

    let user_info_section = null;
    if (user?.id){
        user_info_section = <button onClick={() => setDisplayDropdown(!displayDropdown)}>Welcome, {user.twitch_user_name}</button>
    } else {
        user_info_section = <button className="button-twitch" onClick={onLogin}>Login With Twitch</button>
    }

    return (
        <div className="header">
            <Navigation user={user} />
            <div>
                {user_info_section}
                <DropDownMenu
                            style={ displayDropdown ? {display: 'none'}  : {}}
                            listItems={[
                                , {name: "Account", onClick: routeChange }
                                , {name: "Logout", onClick: logOut }
                            ]}
                        />
            </div>
        </div>
    )
}

export default Header