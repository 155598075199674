import React from 'react';


function DropDownMenu(props) {

    // call back function
    //
    return (
        <div className="drop-down-menu" style={props.style}>
            <ul>
                {props.listItems.map((item, index) => {
                    return  <li key={index} onClick={item.onClick}>{item.name}</li>;
                })}
            </ul>
        </div>
    );
}


export default DropDownMenu;