export default {
    HOST: process.env.REACT_APP_API_HOST
    , USER_INFO_ENDPOINT: {'path': '/oidc/user', 'method': 'GET'}
    , LOGIN_ENDPOINT: {'path': '/oidc/login', 'method': 'GET'}
    , LOGOUT_ENDPOINT: {'path': '/oidc/logout', 'method': 'GET'}
    , AMIIBO_BY_USER_ID_ENDPOINT: {'path': '/api/amiibo/by_user_id/', 'method': 'GET'}
    , AMIIBO_BIN_ENDPOINT: {'path': '/api/amiibo/bin/', 'method': 'GET'}
    , AMIIBO_BY_AMIIBO_ID_ENDPOINT: {'path': '/api/amiibo/', 'method': 'GET'}
    , REGISTER_AMIIBO_ENDPOINT: {'path': '/api/amiibo', 'method': 'POST'}
    , UPDATE_AMIIBO_ENDPOINT: {'path': '/api/amiibo/', 'method': 'PUT'}
    , DELETE_AMIIBO_ENDPOINT: {'path': '/api/amiibo/', 'method': 'DELETE'}
    , SUBMIT_AMIIBO_TO_SUBMISSIONS_APP: {'path': '/api/amiibo/send_to_submissions_app/', 'method': 'PUT'}
    , SUBMIT_AMIIBO_TO_EXTERNAL_DOMAIN: {'path': '/api/amiibo/send_to_external_domain/', 'method': 'PUT'}
    , RANKING_BY_AMIIBO_ID_ENDPOINT: {'path': '/api/amiibo/ranking/', 'method': 'GET'}
    , SINGLES_MATCHES_BY_USER_ID_ENDPOINT: {'path': '/api/singles_matches/by_user_id/', 'method': 'GET'}
    , SINGLES_MATCHES_BY_AMIIBO_ID_ENDPOINT: {'path': '/api/singles_matches/by_amiibo_id/', 'method': 'GET'}
    , CONVERT_NFC_TOOLS_EXPORT_TO_BIN_ENDPOINT: {'path': '/api/amiibo/bin_conversion/from_nfc_tools', 'method': 'POST'}
    , GET_USER_RULESETS: {'path': '/api/rulesets', 'method': 'GET'}
    , GET_MATCH_URL_ENDPOINT: {'path': '/api/utility/get_match_url', 'method': 'GET'}
    , TOP_BY_CHAR_RULESET_ID_ENDPOINT: {'path': '/api/amiibo?', 'method': 'GET'}
    , GET_SPIRIT_SKILL_LIST: {'path': '/api/spirit_skill', 'method': 'GET'}
    , GET_PUBLIC_RULESETS: {'path': '/api/rulesets/public_rulesets', 'method': 'GET'}
    , GET_PLAYABLE_CHARACTERS: {'path': '/api/utility/get_all_characters', 'method': 'GET'}
    , GET_TIER_LISTS: {'path': '/api/tier_list/tier_list', 'method': 'GET'}
    , GET_TIER_LIST_BY_MONTH: {'path': '/api/tier_list/first_dataset_of_given_month', 'method': 'GET'}
};
