import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Simple UI Tag component
 * @param {*} props 
 */
function Tag({ onCloseCallback, children, ...props }) {
    const closeIcon = (onCloseCallback) ? (<a onClick={onCloseCallback}><FontAwesomeIcon icon="times-circle" /></a>) : null
    return (
        <div className="tag">
            {children} {closeIcon}
        </div>
    )
}

export default Tag;