import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import SearchParametersBar from 'components/searchParameters/SearchParametersBar';
import { useGetRulesets } from 'api/RulesetApi';

import AmiiboCard from './components/amiiboCard/AmiiboCard';
import AddAmiiboForm from './components/addAmiiboForm/AddAmiiboForm';

import { selectUser } from 'ducks/userSlice';
import { useGetAmiiboByUserId } from 'api/AmiiboApi';

const sortConfig = {
    availableAttributes: [
        {key:"Active", value:"match_selection_status"}
        , {key:"Rating", value:"rating"}
        , {key:"Win Percentage", value:"win_percentage"}
        , {key:"Ruleset", value:"ruleset_id"}
        , {key:"Total Matches", value:"total_matches"}
    ]
    , default: [
        {key:"Active", value:"match_selection_status", asc:true}
        , {key:"Ruleset", value:"ruleset_id", asc:false}
        , {key:"Rating", value:"rating", asc:false}
    ]
    , sortOrderOverrides: {
        "match_selection_status": {"ACTIVE":0, "STANDBY":1, "INACTIVE":2}
        , "ruleset_id": {"44748ebb-e2f3-4157-90ec-029e26087ad0": 2, "328d8932-456f-4219-9fa4-c4bafdb55776": 1, "af1df0cd-3251-4b44-ba04-d48de5b73f8b": 0}
    }
}

const AmiibosPage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(selectUser);
    const submissionUrl = new URLSearchParams(location.search).get("submissions_callback")
    const externalUrl = new URLSearchParams(location.search).get("external_callback")
    const submissionButtonText = new URLSearchParams(location.search).get("button_text")

    let user_id = new URLSearchParams(location.search).get("user_id");
    
    if (user_id === null){
        user_id = user?.id;
    } 
    
    const [amiibo, setAmiibos] = useGetAmiiboByUserId(user_id);
    const [rulesets, setRulesets] = useGetRulesets();

    //TODO These functions could be done better as a useReducer instead of useState, would avoid copying the full array
    const updateAmiiboAtIndex = (updatedAmiibo, index) => {
        amiibo[index] = updatedAmiibo;
        const newAmiibos = [...amiibo]
        setAmiibos(newAmiibos)
    }

    const deleteAmiiboAtIndex = (index) => {
        amiibo.splice(index, 1)
        const newAmiibos = [...amiibo]
        setAmiibos(newAmiibos)
    }

    const addAmiibo = (newAmiibo) => {
        const newAmiibos = [newAmiibo, ...amiibo]
        setAmiibos(newAmiibos)
    }

    const addMenuConfig = {
        title: "Add"
        , icon: (<FontAwesomeIcon icon={faPlusCircle} />)
        , submenu: (<AddAmiiboForm addAmiibo={addAmiibo}/>)
    }
    
    const menuConfig = {
        filterEnabled: false
        , customMenus: [
            addMenuConfig
        ]
    }

    if (!amiibo) {
        return <React.Fragment />
    } 
    return (
        <div className="amiibos-page">
            <SearchParametersBar 
                className="amiibo-cards"
                data={amiibo}
                updateData={setAmiibos}
                sortConfig={sortConfig}
                menuConfig={menuConfig}
                >
                {amiibo.map((amiibo, i) => (
                    <AmiiboCard 
                        key={amiibo.id} 
                        amiibo={amiibo} 
                        ruleset={rulesets?.find((ruleset)=>{return ruleset.id == amiibo.ruleset_id})}
                        updateAmiibo={(amiibo) => updateAmiiboAtIndex(amiibo, i)}
                        deleteAmiibo={() => deleteAmiiboAtIndex(i)}
                        submissionUrl={submissionUrl}
                        externalUrl={externalUrl}
                        submissionButtonText={submissionButtonText}
                    />
                ))}
            </SearchParametersBar>
        </div>
    );
};

export { AmiibosPage };