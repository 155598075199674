import {useEffect, useState} from 'react';
import axios from 'axios';

import API_CONSTANTS from "./ApiConstants";


export const useGetTierLists = (ruleset_id) => {
    const [tierLists, setTierLists] = useState(); 

    useEffect(() => {
            (async () => {
                const response = await getTierLists(ruleset_id);
                setTierLists(response.data.data)
            })()
    }, [ruleset_id]);

    return [tierLists, setTierLists];
}

const getTierLists = async (ruleset_id) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.GET_TIER_LISTS.path
        , method: API_CONSTANTS.GET_TIER_LISTS.method
        , params: {ruleset_id}
    })
}

export const useGetTierListByMonth = (ruleset_id, requested_date) => {
    const [tierList, setTierList] = useState();
    useEffect(() => {
        (async () => {
            const response = await getTierListByMonth(ruleset_id, requested_date);
            setTierList(response.data.data)
        })()
    }, [ruleset_id, requested_date]);

    return [tierList, setTierList];
}

const getTierListByMonth = async (ruleset_id, requested_date) => {
    return await axios.request({
        url: API_CONSTANTS.HOST + API_CONSTANTS.GET_TIER_LIST_BY_MONTH.path
        , method: API_CONSTANTS.GET_TIER_LIST_BY_MONTH.method
        , params: {ruleset_id, requested_date}
    })
}