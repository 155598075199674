import React, { useState, useCallback } from 'react';

import { postAmiibo } from 'api/AmiiboApi';
import { useGetRulesets } from 'api/RulesetApi';

import Tag from 'components/tag/Tag';

function AddAmiiboForm(props) {

    const [files, setFiles] = useState([])
    const [rulesetId, setRulesetId] = useState()
    const [rulesets, setRulesets] = useGetRulesets();

    const onChangeHandler = (event) => {
        const amiiboFiles = event.target.files;
        setFiles([...amiiboFiles])
    };

    const onDropdownChange = useCallback((event)=>{
        let value = event.target.value;
        setRulesetId(value)
    }, [])

    const onSubmitHandler = async () => {
        for (const file of files){
            const response = await postAmiibo(file, rulesetId)
            props.addAmiibo(response.data.data)
        }
    }

    let acceptedFileTypes = ".bin"

    const selectedRuleset = rulesets?.find((ruleset) => {return ruleset.id == rulesetId})

    const rulesetInfo = (selectedRuleset) ? (
        <div className="amiibo-restrictions">
            <pre>{JSON.stringify(selectedRuleset?.amiibo_restrictions, undefined, 2)}</pre>
        </div>
    ) : null

    return (
        <>
            <form className="add-amiibo-form" onSubmit={()=>{}}>
                <h3>Add New Amiibo</h3>
                <select name="asc" onChange={onDropdownChange}>
                    <option hidden disabled selected value>-- Select a Ruleset --</option>
                    {rulesets?.map((ruleset)=> {
                        return (<option key={ruleset.id} value={ruleset.id}>{ruleset.name}</option>)
                    })}
                </select>
                <input type="file" id="file" name="file" accept={acceptedFileTypes} onChange={onChangeHandler} multiple="multiple"/>
                <button onClick={onSubmitHandler} type="button">Submit</button>
            </form>
            {rulesetInfo}
        </>
    );
}


export default AddAmiiboForm;