import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import { selectUser } from 'ducks/userSlice';
import { useGetSinglesMatchesByUserId } from 'api/SinglesMatchApi';
import { getMatchUrl } from 'api/UtilityApi';
import { useGetRulesets } from 'api/RulesetApi';

import MatchCard from './components/matchCard/matchCard';


const goToTwitchStream = async (created_at_timestamp, stream_url) => {
    const response = await getMatchUrl(created_at_timestamp, stream_url)
    let url = stream_url
    console.log(response)
    if(response.statusCode = 200){
        url = response?.data?.data
    }
    window.open(url, '_blank')
}

const UserSinglesMatchesPage = (props) => {
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(selectUser);

    let user_id = new URLSearchParams(location.search).get("user_id");
    
    if (user_id === null){
        user_id = user?.id;
    } 
    
    const [matches, setMatches] = useGetSinglesMatchesByUserId(user_id);
    const [show_spoiler, setSpoiler] = useState(false);
    const [rulesets, setRulesets] = useGetRulesets();
    
    if (localStorage.getItem("show_spoiler") === null) {
        localStorage.setItem('show_spoiler', true);
    }

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem("show_spoiler"));
        setSpoiler(items);
        
      }, []);

    const handleButtonOnClick = () => {
        localStorage.setItem("show_spoiler", !show_spoiler);
        setSpoiler(!show_spoiler);
    }

    if (!matches) {
        return <React.Fragment />
    }

    if (matches.length === 0) {
        return <h4 className="no-match-text">Once your amiibo fights a match, it will show up here!</h4>
    }
    else {
        return (
            <div>
                <div className="spoiler-button">
                    <button onClick={handleButtonOnClick}>
                        {show_spoiler ? <span>Hide Match Results</span> : <span>Show Match Results</span>}
                    </button>
                </div>
                <div className="match-cards">
                    {matches.map((match, i) => { 
                        let mstDate = moment.utc(match.created_at)
                        mstDate.subtract({hours: 7}) //Why is it so hard to just tell it to display the time with -7
                        let match_url = match?.match_metadata?.vod_url
                        if (!match_url){
                            match_url = match?.match_metadata?.stream_url
                        }
                            
                        return (
                            <MatchCard 
                            key={i}
                            user_id={user_id}
                            show_result={show_spoiler}
                            date={mstDate.format("YYYY-MM-DD HH:mm")}
                            match={match}
                            match_url={match_url}
                            ruleset_name={rulesets?.find((ruleset)=>{return ruleset.id == match.ruleset_id}).name}
                            />
                        )
                    })}
                </div>
            </div>
        );
    }
};

export { UserSinglesMatchesPage };