import React from 'react';
import {StockIcon} from 'components/icons/stockIcon/stockIcon';


const MiniMatchCard = ({match, amiibo_id, ...props}) => {
    let match_result = "";
    // info to be displayed will be enemy of given amiibo_id
    let opponent;
    let rating_change;

    if (match.winner_info.id === amiibo_id) {
        match_result += " winner";
        rating_change = Number(match.winner_info.rating_change).toFixed(2);
    }
    else if (match.loser_info.id === amiibo_id) {
        match_result += " loser";
        rating_change = Number(match.loser_info.rating_change).toFixed(2);
    }

    if (match.fp1.id === amiibo_id){
        opponent = match.fp2
    } else {
        opponent = match.fp1
    }

    let rating = Number(opponent.rating).toFixed(2);
    if (opponent.rating_sigma >= 2.5) {
        rating = "Unrated";
    }
    return (
        <div className={"mini-match-card" + match_result}>
            <div className={"circle" + match_result}>
                <div className="circle-text">
                    <span>{rating_change}</span>
                </div>
            </div>
            <div className="mini-body">
                <span className="mini-amiibo-name">{opponent.name}</span>
                <br /> 
                <span className="mini-trainer-name">{opponent.trainer_name}</span>
            </div>
            <div className="mini-footer">
                <StockIcon cssClass="mini-stock-icon" character_id={opponent.character_id} />
                <span className="mini-trainer-info">{rating}</span>
            </div>
        </div>
    )
};

export default MiniMatchCard;